import BaseCard from './atoms/BaseCard';
import { Row, Col } from 'react-bootstrap';
import Typography from './atoms/Typography';
import ImageSection from './atoms/ImageSection';
import HazelcastImage from '../assets/images/HazelcastUnifiedRealTimeDataPlatform.png';
import { FaCheck } from "react-icons/fa6";
import './HazelcastPlatformSection.css';

const hazelcastCardData = {
    title: "Hazelcast Platform",
    description: [
        '기업이 스트리밍 데이터에 즉각적으로 대응할 수 있게 해주는 통합 실시간 데이터 플랫폼입니다.',
        '고성능 스트림 처리 능력과 내장 고속 데이터 저장소를 결합하여 비즈니스 핵심 프로세스와 애플리케이션을 자동화, 간소화하고 향상시킵니다.'
    ],
    list: [
        {
            title: "커스텀 코드 감소",
            detail: "인프라 작업을 줄여주는 고성능 분산 병렬 처리 환경",
        },
        {
            title: "운영 부담 감소",
            detail: "관리해야 할 클러스터와 서버 수 감소",
        },
        {
            title: "고속 캐싱",
            detail: "더 빠른 처리 속도와 낮은 지연 시간을 통한 성능 향상",
        }
    ],
    imageUrl: HazelcastImage,
    imageDescription: "Hazelcast Unified Real-Time Data Platform",
};

function HazelcastPlatformSection() {
    const { title, description, list, imageUrl, imageDescription } = hazelcastCardData;

    return (
        <BaseCard color="baseCard-ice" dataAos="fade-up">
            <Row>
                <Col md={6}>
                    <div className="d-md-block">
                        <Typography variant="title1" className="platform-card-title" color="sky">{title}</Typography>
                    </div>
                    <div className="d-md-none order-2" data-aos="fade-left">
                        <ImageSection
                            image={imageUrl}
                            style={{ maxHeight: '550px' }}
                            imageDescription={imageDescription} />
                    </div>
                    <div className="d-md-block">
                        {description.map((paragraph, index) => (
                            <Typography key={index} variant="body1" className="card-description">{paragraph}</Typography>
                        ))}
                        {list.map((benefit, index) => (
                            <div key={index}>
                                <Typography variant="title5">
                                    <FaCheck color='#10A4B3' style={{ fontSize: '30px', marginRight: '2%' }} />
                                    {benefit.title}
                                </Typography>
                                <Typography variant="body3" className="list-detail">
                                    {benefit.detail}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </Col>
                <Col md={6} className="d-none d-md-block">
                    <ImageSection
                        image={imageUrl}
                        style={{ maxHeight: '550px' }}
                        imageDescription={imageDescription} />
                </Col>
            </Row>
        </BaseCard>
    );
}

export default HazelcastPlatformSection;