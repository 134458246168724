import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import globalCardDetails from './global-carousel-config';
import localCardDetails from './local-carousel-config';
import InfiniteCarouselMarquee from './molecules/InfiniteCarouselMarquee';
import './CustomerObsessionSection.css';

const CustomerObsessionSection = () => {
    return (
        <BaseCard color="baseCard-ice" baseClassName='customerCard-base' bodyClassName='customerCard-body'>
            <Typography variant="title2" color="gradient-raspberry-carrot" className="customerObsession-tit">
                우리는 고객에게 진심입니다.
            </Typography>
            <Typography variant="body2" className="customerObsession-subTit">
                Hazelcast는 단순한 기술 회사가 아닙니다. 우리는 고객과 개발자 커뮤니티에 진심입니다. 우리가 작성하는 코드의 모든 줄, 우리가 하는 모든 혁신은
                오직 하나의 목적을 갖습니다. 당신에게 능력과 기쁨을 드리는 것입니다.<br /><br />
                우리는 귀 기울여 듣고, 장애물을 예측하며, 성공을 위한 맞춤형 솔루션을 만드는 파트너가 될 것입니다. 우리는 당신의 바람을 현실로 만들겠다는
                집념으로 기대를 뛰어넘습니다.<br /><br />
                우리는 제품을 넘어서, 여정을 약속합니다. 끊임없는 대화를 통해 당신으로부터 배우고, 변화하는 요구 사항에 맞게 제품을 개선해갑니다. 
                당신의 목소리가 우리의 미래를 만듭니다.<br /><br />
                <b>진심으로 소중하게 여기겠습니다. Hazelcast와 함께 나아가세요.</b>
            </Typography>
            <InfiniteCarouselMarquee cardDetails={globalCardDetails} className="global-clients-container customerObsession-carousel-container" />
            <InfiniteCarouselMarquee cardDetails={localCardDetails} className="local-clients-container customerObsession-carousel-container" />
        </BaseCard>
    );
};

export default CustomerObsessionSection;