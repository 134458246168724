import Button from '../atoms/Button';
import Card from 'react-bootstrap/Card';
import Typography from '../atoms/Typography';
import './ImageCard.css';

function ImageCard({ cardDetail, className, onClick, dataAos }) {
  const handleClick = () => {
    if (onClick) {
      window.open(cardDetail.href, '_blank', 'noopener, noreferrer');
    }
  }

  return (
    <Card
      className={`image-card ${className} ${onClick ? 'img-card-clickable' : ''}`}
      data-aos={dataAos}
      onClick={handleClick}
      >
      <Card.Img variant="top" src={cardDetail.imgUrl} className='card-img-top' alt={cardDetail.title} />
      <Card.Body className='imgCard-body'>
        <Typography variant="title5" color="dusk">
          {cardDetail.title}
        </Typography>
        <Typography variant="title4" color="gradient-sky-deepSea" style={{ marginBottom: '6px' }}>
          {cardDetail.subTitle}
        </Typography>
        <Typography variant="body2" style={{ marginBottom: '10px' }}>
          {cardDetail.text}
        </Typography>
        {cardDetail.btn &&
          <div className='imgCard-button-container'>
            <Button variant="primary" newTab href={cardDetail.href}>
              <Typography variant="body3">
                더 알아보기
              </Typography>
            </Button>
          </div>
        }
      </Card.Body>
    </Card>
  );
}

export default ImageCard;