import React from 'react';
import './typography.css';

const Typography = ({ variant, color, className, children, ...props }) => {  
  const finalClassName = `typography ${className || ''} ${variant} ${color}`;

  return <p className={finalClassName} {...props}>{children}</p>;
};

export default Typography;
