const solutionSectionCardDetails = [
    {
        type: [`산업`],
        industry: [`금융`],
        title: `리테일 뱅킹(소매 금융)`,
        href: "https://hazelcast.com/use-cases/retail-banking/",
        text: `Hazelcast는 많은 주요 소매 금융 사이트에서 예측하기 어려운 부하를 처리하는데 사용됩니다. 은행은 지연 시간의 감소, 탄력적인 확장성, 연중무휴의
        안정성을 통해 다양한 제품과 서비스를 보다 향상된 고객 경험과 함께 제공할 수 있습니다.`
    },
    {
        type: [`산업`],
        industry: [`임의 소비재`, `정보 기술`, `소매`],
        title: `전자상거래`,
        href: "https://hazelcast.com/use-cases/e-commerce/",
        text: `Hazelcast는 많은 주요 전자상거래 사이트에서 디지털 매장이 예측할 수 없는 온라인 구매자의 부하를 처리하는 것을 도와줍니다. 전자상거래 업체는 감소된 
        지연 시간, 탄력적인 확장성 및 연중무휴의 안정성을 통해 제품 판매를 보다 향상된 고객 경험과 함께 제공할 수 있습니다.`
    },
    {
        type: [`아키텍처`],
        industry: [``],
        title: `아키텍처`,
        href: "https://hazelcast.com/use-cases/architectures/",
        text: `Hazelcast 플랫폼의 가장 일반적인 배포 아키텍처에 대해 자세히 알아보세요.`
    },
    {
        type: [`아키텍처`],
        industry: [`임의 소비재`, `필수 소비재`, `에너지`, `금융`, `건강 관리`, `공업`, `정보 기술`, `재료`, `미디어`, `소매`, `통신 서비스`],
        title: `Hazelcast 플랫폼 vs Apache Flink`,
        href: "https://hazelcast.com/use-cases/hazelcast-platform-vs-apache-flink/",
        text: `Hazelcast 플랫폼을 Apache Flink의 대체제로 고려해보세요. 통합 아키텍처가 애플리케이션 개발의 복잡성을 줄여주고, 개발자들이 사업 목표와 더욱 가까이 작업할 수 있게
        도와줍니다.`
    },
    {
        type: [`산업`],
        industry: [`항공`],
        title: `항공 산업`,
        href: "https://hazelcast.com/use-cases/airline-industry/",
        text: `항공 여행이 재개되어 이전보다 훨씬 큰 규모로 확장될 전망입니다. 여행의 증가는 항공사에 더 많은 조정 요소를 요구하며, 고객의 실시간 요구에 부응할 필요성도 커지고 있습니다.
        고객들에게 최신 정보를 신속하게 제공하여 만족도를 높이는 것은 재이용을 유도하는 중요한 요소가 됩니다.`
    },
    {
        type: [`아키텍처`, `인프라`],
        industry: [`필수 소비재`, `공업`, `정보 기술`, `재료`, `미디어`, `소매`, `통신 서비스`],
        title: `Memcached 업그레이드`,
        href: "https://hazelcast.com/use-cases/memcached-upgrade/",
        text: `Memcached를 Hazelcast로 간편하게 업그레이드하세요. Hazelcast는 탄력적인 확장 가능성, 빠른 성능, 뛰어난 가용성 및 손쉬운 관리 등의 추가적인 이점을 제공합니다. 
        Hazelcast 클러스터로 연결하기 위해 애플리케이션/클라이언트 설정을 간단히 변경하는 것 외에 별도의 수정 작업은 필요 없습니다. 이미 많은 실제 환경에서 Memcached의 대체제 혹은
        성능 강화 플러그인으로써 Hazelcast를 성공적으로 도입하였습니다.`
    },


    {
        type: [`인프라`],
        industry: [``],
        title: `Apache Cassandra 강화`,
        href: "https://hazelcast.com/use-cases/apache-cassandra-enhancement/",
        text: `Cassandra의 속도를 Hazelcast로 한 단계 높여보세요. 뛰어난 속도로 잘 알려진 NoSQL 데이터베이스인 Apache Cassandra는 디스크 기반의 I/O 한계로 인해 
        어느 정도 성능 제약을 가집니다. Hazelcast IMDG와의 통합은 Cassandra의 강점은 그대로 유지하면서 데이터 접근성을 밀리초 단위의 낮은 지연 시간으로 대폭 개선합니다. 
        이렇게 하면 Cassandra의 뛰어난 가용성과 확장성이 결합된 솔루션을 얻을 수 있습니다.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `Software AG Terracotta Ehcache의 대안`,
        href: "https://hazelcast.com/use-cases/software-ag-terracotta-ehcache-replacement/",
        text: `Software AG의 Terracotta Ehcache 대체를 고민 중인 기업이라면 Hazelcast를 고려해 보세요. 풍부한 기능성을 갖추면서도 소유 및 운영의 편리함이 대폭 개선된 경험을 제공할 것입니다. `
    },
    {
        type: [`산업`],
        industry: [``],
        title: `ETL과 데이터 통합`,
        href: "https://hazelcast.com/use-cases/etl-and-data-ingestion/",
        text: `ETL은 데이터를 '추출(Extract)', '변환(Transform)', '로드(Load)'하는 과정을 말합니다. 이는 각각 데이터 소스에서 정보를 가져오기, 데이터를 처리하는 과정, 
        그리고 처리된 데이터를 데이터베이스와 같은 최종 목적지로 전송하는 단계를 의미합니다. 1970년대에 시작된 ETL이 시대를 넘어 여전히 중요한 이유는 데이터를 처리하여 
        사용자와 응용 프로그램에 제공하는 핵심적 방법론이기 때문입니다. 데이터 엔지니어링 및 제품 팀들은 다양한 소스로부터 수집한 데이터를 필요한 형태로 가공하여 여러 대상에
        전달하는 ETL 방식을 활용합니다.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `Redis의 대안`,
        href: "https://hazelcast.com/use-cases/redis-replacement/",
        text: `Redis는 기본적인 데이터 캐싱 요구에는 충분히 대응하지만, 복잡한 현실의 요구사항을 만족시키기 위해서는 단순 캐싱을 넘어서는 기능이 필요합니다. Redis의 
        확장 문제나 보안상의 난제에 직면했다면, Hazelcast로의 전환을 고려해볼 때입니다.`
    },
    {
        type: [`아키텍처`],
        industry: [``],
        title: `스프링 캐시`,
        href: "https://hazelcast.com/use-cases/spring-cache/",
        text: `스프링 프레임워크를 기반으로 한 애플리케이션들은 Hazelcast를 활용하여 실시간으로 데이터를 공유하며 효율적인 캐싱을 구현할 수 있습니다.`
    },
    {
        type: [``],
        industry: [``],
        title: `서비스형 컨테이너`,
        href: "https://hazelcast.com/use-cases/cache-as-a-service/",
        text: `Hazelcast는 확장성, 안정성, 그리고 빠른 성능을 갖춘 캐싱 서비스를 클라우드 솔루션으로 제공합니다. 어플리케이션은 Hazelcast를 데이터베이스의 보조 캐시로 활용하거나, 
        캐싱 서비스를 통해 데이터베이스와 직접 연결하여 사용할 수 있습니다.`
    },
    {
        type: [``],
        industry: [``],
        title: `응용 프로그램 가속화 및 확장`,
        href: "https://hazelcast.com/use-cases/application-acceleration-and-scaling/",
        text: `Hazelcast는 디스크 기반 데이터베이스의 데이터 접근 속도를 빠르게 하고 지연 시간을 감소시켜, SaaS 또는 맞춤형 내부 응용 프로그램의 처리 속도와 용량을 
        효과적으로 향상시킵니다. 당신의 애플리케이션과 데이터베이스 사이에 Hazelcast 인메모리 스토리지를 추가하여, 더 많은 사용자 처리와 더 빠른 반응 시간을 달성할 수
        있습니다. 애플리케이션에 의해 Hazelcast에 적용된 데이터 업데이트는 기존 데이터베이스와의 동기화를 유지하면서 전달됩니다.`
    },
    {
        type: [`아키텍처`],
        industry: [``],
        title: `메시징`,
        href: "https://hazelcast.com/use-cases/messaging/",
        text: `Hazelcast를 활용하면 애플리케이션이 높은 속도로 실시간 통신을 가능하게 하는 가벼우면서도 신속한 메시징 시스템을 구축할 수 있습니다. 
        발행-구독 방식을 통해, 여러분의 애플리케이션은 "토픽"이라 불리는 지정된 채널에 정보를 "발행"할 수 있고, 이를 구독하고 있는 다수의 구독자가 그 토픽에서 메시지를 수신할 수 있습니다.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `VMware Tanzu Gemfire 대안`,
        href: "https://hazelcast.com/use-cases/vmware-tanzu-gemfire-replacement/",
        text: `점점 더 많은 기업들이 VMware Tanzu Gemfire 대신 Hazelcast IMDG를 선호하고 있습니다. 여러 주요 은행, 전자 상거래 업체, 통신 회사들이 이미 Hazelcast로 전환했습니다. 
        이들 대부분은 비용과 소유권에 관련된 문제가 결국 지속 가능한 경영에 큰 부담으로 작용했다고 전합니다.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `Oracle Coherence 대안`,
        href: "https://hazelcast.com/use-cases/oracle-coherence-replacement/",
        text: `Oracle Coherence를 Hazelcast®로 대체했을 때 얻을 수 있는 혜택에 대해 살펴보세요.`
    },
    {
        type: [``],
        industry: [``],
        title: `Hibernate Second-Level Cache`,
        href: "https://hazelcast.com/use-cases/hibernate-second-level-cache/",
        text: `Hazelcast는 Hibernate 3과 4와의 연동을 통해 Hazelcast의 IMap 기술을 활용한 분산 캐싱을 지원합니다. 확장성, 메모리 내 백업, 그리고 자동 데이터 제거 등 
        Hazelcast의 핵심 기능들을 Hibernate에 제공함으로써, 더욱 강력한 데이터 관리 솔루션을 제공합니다.`
    },
    {
        type: [`아키텍처`],
        industry: [``],
        title: `데이터베이스 캐싱`,
        href: "https://hazelcast.com/use-cases/database-caching/",
        text: `기업들은 필수 애플리케이션의 안정적인 확장성을 달성하기 위해, 자주 접근하는 데이터를 메모리 상에서 빠르게 가져올 수 있게 하는 데이터베이스 캐싱 기술에 
        의존하고 있습니다. 사용자 데이터가 급증함에 따라, 다양한 규모의 기업들이 서비스 품질을 보장하고, 데이터 서비스의 부담을 줄이며, 높은 가용성을 유지하기 위해 인메모리 
        기반의 솔루션을 채택하고 있습니다.`
    },
    {
        type: [`아키텍처`],
        industry: [``],
        title: `JCache 공급자`,
        href: "https://hazelcast.com/use-cases/jcache-provider/",
        text: `Hazelcast는 JCache와의 손쉬운 통합을 통해 기업이 애플리케이션의 확장성을 개선하고 자주 사용하는 데이터에 대한 신속한 접근을 용이하게 관리할 수 있도록 해줍니다. 
        Hazelcast는 JCache 시스템에 완벽하게 호환되는 구현을 통해 투명한 캐싱 경험을 제공합니다.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `고속 배치 처리`,
        href: "https://hazelcast.com/use-cases/fast-batch-processing/",
        text: `Hazelcast는 성능 최적화를 광범위하게 적용하여 Spark나 Flink와 비교했을 때 최대 15배 빠른 배치 처리를 가능하게 합니다. Hadoop과 비교해도 월등한 성능을 자랑합니다.`
    },
    {
        type: [``],
        industry: [``],
        title: `오픈 뱅킹`,
        href: "https://hazelcast.com/use-cases/open-banking/",
        text: `인메모리 컴퓨팅 기술이 유럽 연합의 개정 결제 서비스 지침(PSD2) 요구 사항을 충족시켜 오픈 뱅킹을 현실화하는 방법을 탐색해보세요.`
    },
    {
        type: [`솔루션`],
        industry: [``],
        title: `360° 고객 뷰`,
        href: "https://hazelcast.com/use-cases/360-customer-view/",
        text: `인메모리 컴퓨팅의 빠른 속도가 머신러닝으로 활성화되고 인공지능으로 강화된 통합된 관점을 통해, 고객의 참여 경험을 전방위적으로 혁신합니다.`
    },
    {
        type: [``],
        industry: [``],
        title: `마이크로서비스 - 인메모리 배포 단순화`,
        href: "https://hazelcast.com/use-cases/microservices/",
        text: `마이크로서비스는 개발, 테스팅, 배포, 고장 허용, 그리고 스케일링을 단순화하는 데 도움을 줍니다. 이제 현대 기업 아키텍처를 실행하기 위해 만들어진 초고속, 
        경량 인프라를 사용하여 더 높은 처리량과 낮은 지연 시간을 얻으세요.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `인메모리 데이터 그리드`,
        href: "https://hazelcast.com/use-cases/in-memory-data-grid/",
        text: `속도가 경쟁력이며 시간이 바로 자본인 현대에서 Hazelcast는 가장 중요한 속도의 차이를 만들어냅니다. 이는 조직 전반에 걸쳐 혁신의 새로운 가능성을 열고, 
        귀사가 항상 최적의 실행 수준을 유지할 수 있도록 확장성과 탄력성을 보장하며, 분산되어 병렬로 실행되는 안정적인 시스템과, 사용자와 고객의 업무 중단을 최소화하는 
        통합 보안을 통해 안정성을 제공합니다.`
    },
    {
        type: [`인프라`],
        industry: [``],
        title: `실시간 스트리밍 분석`,
        href: "https://hazelcast.com/use-cases/real-time-streaming-analytics/",
        text: `Hazelcast와 함께 스트리밍 분석 애플리케이션에 스트림 처리 기능을 통합하여 데이터를 실시간으로 빠르게 처리하고 즉각적으로 대응할 수 있습니다.`
    },
    {
        type: [`솔루션`],
        industry: [``],
        title: `디지털 전환`,
        href: "https://hazelcast.com/use-cases/digital-transformation/",
        text: `디지털 전환은 오늘날 기업의 모든 영역을 재정의하고 있으며, 인-메모리 기술은 끊임없이 진화하는 데이터 중심의 비즈니스 환경에서 중추적인 역할을 합니다. 
        비즈니스에 필수적인 속도, 확장성, 안정성을 추구한다면 Hazelcast가 그 해답을 제공합니다.`
    },
    {
        type: [`솔루션`],
        industry: [``],
        title: `리스크 관리`,
        href: "https://hazelcast.com/use-cases/risk-management/",
        text: `Hazelcast 인-메모리 컴퓨팅 플랫폼은 기업의 위험 관리 데이터를 일원화하고, 분석과 보고를 보다 수월하게 만들어주는 선도적인 기능들로 시장을 선도하고 있습니다.`
    },
    {
        type: [`솔루션`],
        industry: [``],
        title: `사물인터넷(IoT) `,
        href: "https://hazelcast.com/use-cases/internet-of-things/",
        text: `연결된 기기들이 끊임없이 데이터를 전송하는 오늘날, Hazelcast 인-메모리 컴퓨팅 플랫폼은 귀사의 IoT 생태계를 효과적으로 관리할 수 있는 최적의 해결책을 제시합니다.`
    },
    {
        type: [``],
        industry: [``],
        title: `웹 세션 클러스터링`,
        href: "https://hazelcast.com/use-cases/web-session-clustering/",
        text: `Hazelcast의 선도적인 인메모리 데이터 그리드 기술은 사용자 세션을 클러스터 내에서 안정적으로 관리하며, 데이터의 안전성을 위해 복수의 백업을 보유한 웹 세션 클러스터링 솔루션을 제공합니다.`
    },
    {
        type: [``],
        industry: [``],
        title: `캐싱 사용 사례`,
        href: "https://hazelcast.com/use-cases/caching/",
        text: `현대의 세상은 항상 연결되어 있고, 방대한 양의 데이터, 빠른 처리 속도, 그리고 높은 기대치를 충족시킬 새로운 방식의 데이터 처리를 요구합니다. 성공과 실패를 가를 수 있는 극히 짧은 시간 내에, 
        Hazelcast의 인-메모리 솔루션은 스케일링이 가능하며 유연한 데이터 캐싱으로 눈부신 속도를 제공합니다.`
    },
];

export default solutionSectionCardDetails;