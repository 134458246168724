import BaseCard from "./atoms/BaseCard";
import { Row, Col, Card } from "react-bootstrap";
import Typography from "./atoms/Typography";
import IconCard from "./molecules/IconCard";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import PipelineDiagramBefore from '../assets/images/pipeline-diagram.svg';
import PipelineDiagramAfter from '../assets/images/pipeline-diagram-after.svg';
import Chat from '../assets/images/widget-icons/chat.png';
import Bug from '../assets/images/widget-icons/bug.png';
import Cctv from '../assets/images/widget-icons/cctv.png';
import { Link } from "react-router-dom";
import { MdOutlineArrowOutward } from "react-icons/md";
import './StreamingDataSection.css';

const cardDetails = [
    {
        imgUrl: Chat,
        title: "실시간 제안",
        text: "고객이 웹사이트를 탐색하는 동안, 즉각적인 추천을 통해 더 나은 사용자 경험을 제공합니다."
    },
    {
        imgUrl: Bug,
        title: "예측형 유지보수",
        text: "장비의 고장이 발생하기 전에 그 가능성을 사전에 인식하고 대응할 수 있게 도와줍니다."
    },
    {
        imgUrl: Cctv,
        title: "사기 탐지",
        text: "거래가 처리되는 중에 머신러닝 알고리즘을 활용하여 사기 활동을 실시간으로 탐지합니다."
    }
];

const StreamingDataSection = () => {
    return (
        <BaseCard color="baseCard-ice">
            <Typography variant="title3" color="sky" className="streamingData-tit">
                스트리밍 데이터를 활용해 즉각적으로 대응하세요.
            </Typography>
            <Typography variant="body1" className="streamingData-text">
                전통적인 분석 구조에서는 실시간 데이터를 활용하려면 우선 데이터베이스에 저장해야 했습니다. <br /> 
                이로 인해 데이터의 분석과 이해는 늦어지고 고객과의 교류, 수익 창출, 위험 완화의 기회는 놓치게 됩니다.
            </Typography>
            <div className="img-compare-container">
                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={PipelineDiagramBefore} alt="Pipeline diagram before Hazelcast" />}
                    itemTwo={<ReactCompareSliderImage src={PipelineDiagramAfter} alt="Pipeline diagram after Hazelcast" />}
                />
            </div>
            <Typography variant="body1" className="streamingData-text2">
                Hazelcast는 이동 중인 데이터와 정지 상태의 데이터를 실시간으로 끊김없이 통합하여 독특한 이점을 제공합니다. 이를 통해 기업들은 분초를 다투는 모든 기회, 문제, 또는 위협에도 적극적으로 대응할 수 있게 됩니다.
            </Typography>
            <Typography variant="title4" className="streamingData-subTit">
                Hazelcast 활용 사례
            </Typography>
            <Row className="iconCards-row">
                {cardDetails.map((cardDetail, index) => (
                    <Col key={index} className="mb-4 md-mb-0" xs={12} md={4}>
                        <IconCard cardDetail={cardDetail} />
                    </Col>
                ))}
            </Row>
            <Link to="/solutions" className="useCases-a">
                <Typography color="deep-sea">
                    더 많은 사례 보러가기 <MdOutlineArrowOutward />
                </Typography>
            </Link>
        </BaseCard>
    );
};

export default StreamingDataSection;