import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // 페이지 별 동적 메타 태그 생성
import { Container } from 'react-bootstrap';
import RouteChangeTracker from './utils/RouteChangeTracker';
import ScrollToTop from './utils/ScrollToTop';
import Layout from './layouts/Layout';
import MainPage from './pages/MainPage';
import WhyHazelcastPage from './pages/WhyHazelcastPage';
import AboutUsPage from './pages/AboutUsPage';
import PlatformOverviewPage from './pages/PlatformOverviewPage';
import SolutionsPage from './pages/SolutionsPage';
import ErrorPage from './pages/ErrorPage';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
    // Animate on Scroll
    useEffect(() => {
        AOS.init({
            once: true, // 로드 후 첫 스크롤 시에만
            disable: window.innerWidth < 576, // 모바일 화면 사이즈에서 비활성화
            duration: 700,
            easing: "ease-out-cubic",
        });
    });

    return (
        <HelmetProvider>
            <Router>
                <RouteChangeTracker />
                <Layout>
                    <Container fluid="lg">
                        <ScrollToTop />
                        <Routes>
                            <Route path="/" element={<MainPage />} />
                            <Route path="/why-hazelcast" element={<WhyHazelcastPage />} />
                            <Route path="/about-us" element={<AboutUsPage />} />
                            <Route path="/platform" element={<PlatformOverviewPage />} />
                            <Route path="/platform-overview" element={<PlatformOverviewPage />} />
                            <Route path="/solutions" element={<SolutionsPage />} />
                            <Route path="/solutions-all" element={<SolutionsPage />} />
                            <Route path="*" element={<Navigate replace to="/error" />} />
                            <Route path="/error" element={<ErrorPage />} />
                        </Routes>
                    </Container>
                </Layout>
            </Router>
        </HelmetProvider>
    );
};

export default App;