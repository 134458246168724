import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NavLink, Link, useLocation } from 'react-router-dom';
import Typography from '../components/atoms/Typography';

const Navigation = ({ menuItems }) => {
    const [activeDropdown, setActiveDropdown] = useState(null);

    const location = useLocation();

    const handleNavLinkClick = (href) => {
        if (location.pathname === href) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Col xl={6} lg={8} className="d-none d-lg-block">
            <nav className='nav justify-content-end'>
                <ul className='nav-list'>
                    {menuItems.map((menuItem, index) => (
                        <li
                            key={index}
                            className={`hover-underline ${location.pathname === menuItem.href ? 'active' : ''}`}
                            onMouseEnter={() => setActiveDropdown(menuItem)}
                            onMouseLeave={() => setActiveDropdown(null)}
                        >
                            <NavLink
                                to={menuItem.href}
                                onClick={() => handleNavLinkClick(menuItem.href)}
                                className={({ isActive }) => isActive ? "nav-menu active" : "nav-menu"}
                            >
                                <Typography variant="body3">
                                    {menuItem.title}
                                </Typography>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </Col>
    );
};

export default Navigation;
