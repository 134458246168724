import { Helmet } from "react-helmet-async";
import Typography from "../components/atoms/Typography";
import MyBreadcrumb from "../components/molecules/Breadcrumb";
import PageTitle from "../components/molecules/PageTitle";
import SolutionsSection from "../components/SolutionsSection";

const breadcrumbItems = [
    {
        text: 'Home',
        path: '/'
    },
    {
        text: '솔루션',
        path: '/solutions'
    }
]

const SolutionsPage = () => {
    return (
        <>
            <Helmet>
                <title>솔루션 | Hazelcast Korea</title>
                <meta name="description" content='Hazelcast의 아키텍처, 인프라를 비롯한 유형 별 활용 예시와 금융, 공공 서비스 등의 산업 별 사용 사례를 살펴보세요.' />
                <meta property='og:description' content='Hazelcast의 아키텍처, 인프라를 비롯한 유형 별 활용 예시와 금융, 공공 서비스 등의 산업 별 사용 사례를 살펴보세요.' />
                <meta property='og:url' content='https://hazelcastkorea.com/solutions' />
            </Helmet>
            <MyBreadcrumb breadcrumbItems={breadcrumbItems} />
            <PageTitle colClassName="whyHazelcast-pagetit">
                <Typography variant="title1" color="gradient-sky-lime">
                    사용 사례
                </Typography>
            </PageTitle>

            <SolutionsSection />
        </>
    );
};

export default SolutionsPage;