import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import './breadcrumb.css';

// 모듈화 필요 - 라우팅
function MyBreadcrumb({ breadcrumbItems }) {
    return (
        <Breadcrumb className='my-breadcrumb'>
            {breadcrumbItems.map((item, index) => {
                const isLast = index === breadcrumbItems.length - 1;
                return (
                    <Breadcrumb.Item
                        key={index}
                        linkAs={isLast ? 'span' : Link}
                        linkProps={{ to: item.path }}
                        active={isLast}
                    >
                        {item.text}
                    </Breadcrumb.Item>
                )
            })}


            {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                왜 헤이즐캐스트인가?
            </Breadcrumb.Item>
            <span className="breadcrumb-custom-separator"> &gt; </span>
            <Breadcrumb.Item active>
                왜 헤이즐캐스트인가?
            </Breadcrumb.Item> */}
        </Breadcrumb>
    );
}

export default MyBreadcrumb;