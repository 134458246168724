import { useState, useEffect, useMemo } from "react";
import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col } from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';
import Button from "./atoms/Button";
import MyDropdown from "./atoms/Dropdown";
import SearchBar from "./atoms/SearchBar";
import UseCaseCard from "./molecules/UseCaseCard";
import RenderedUseCaseCards from "../utils/RenderedUseCaseCards";
import solutionSectionCardDetails from "./SolutionSectionCardDetails";
import { MdOutlineArrowOutward } from "react-icons/md";
import './SolutionsSection.css';

const typeDropdownContents = {
    title: "모든 유형",
    options: [
        "모든 유형",
        "아키텍처",
        "산업",
        "인프라",
        "솔루션"
    ]
};
const industryDropdownContents = {
    title: "모든 산업",
    options: [
        "모든 산업",
        "항공",
        "임의 소비재",
        "필수 소비재",
        "에너지",
        "금융",
        "건강 관리",
        "공업",
        "정보 기술",
        "재료",
        "미디어",
        "소매",
        "공급망 및 물류",
        "통신 서비스",
        "공공 서비스"
    ]
}

const SolutionsSection = () => {
    // 검색어 
    const [searchTerm, setSearchTerm] = useState('');
    // 선택된 타입과 산업
    const [typeSelection, setTypeSelection] = useState(null);
    const [industrySelection, setIndustrySelection] = useState(null);
    // 현재 페이지
    const [currentPage, setCurrentPage] = useState(1);
    // 한 페이지당 카드 수
    const itemsPerPage = 9;
    // 필터링 조건 여부
    const [filterCondition, setFilterCondition] = useState(false);

    // 필터링 조건 발생
    useEffect(() => {
        if (filteredData.length !== solutionSectionCardDetails.length) {
            setFilterCondition(true);
        } else {
            setFilterCondition(false);
        }
    }, [typeSelection, industrySelection, searchTerm]);

    // 필터링된 데이터 계산 (검색 + 선택된 드롭다운 옵션)
    const filteredData = useMemo(() => {
        return solutionSectionCardDetails.filter(card => {

            const typeMatch = !typeSelection || card.type.includes(typeSelection);
            const industryMatch = !industrySelection || card.industry.includes(industrySelection);
            const searchTermMatch = !searchTerm || card.text.toLowerCase().includes(searchTerm.toLowerCase());

            return typeMatch && industryMatch && searchTermMatch;
        });
    }, [searchTerm, typeSelection, industrySelection]);

    // 현재 페이지에 표시할 데이터 계산
    const currentItems = useMemo(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        return filteredData.slice(indexOfFirstItem, indexOfLastItem);
    }, [currentPage, itemsPerPage, filteredData]);

    // 페이지네이션 아이템 생성
    const paginationItems = useMemo(() => {
        let items = [];
        for (let number = 1; number <= Math.ceil(filteredData.length / itemsPerPage); number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    }, [filteredData, itemsPerPage, currentPage]);

    // 검색 처리 함수
    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
        setCurrentPage(1);
    };

    // 드롭다운 선택 처리 함수
    const handleTypeSelect = (option) => {
        setTypeSelection(option === '모든 유형' ? null : option);
        setCurrentPage(1);
    };
    const handleIndustrySelect = (option) => {
        setIndustrySelection(option === '모든 산업' ? null : option);
        setCurrentPage(1);
    };

    // 페이지 변경 시 상단으로 스크롤
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        }
        );
    }, [currentPage]);

    return (
        <BaseCard color="baseCard-dusk" baseClassName="solution-section-baseCard">
            <Row className="solution-sec-link-row">
                <Col>
                    <a className="solution-sec-link" href='https://hazelcast.com/use-cases/' target="_blank" rel="noopener noreferrer">
                        <Typography variant="body3">
                            더 많은 사례 보러가기 <MdOutlineArrowOutward className="solution-sec-link-icon" />
                        </Typography>
                    </a>
                </Col>
            </Row>
            <Row className="g-4 filter-form-container">
                <Col md={4} className="mb-3 mb-md-0">
                    <MyDropdown dropdownContents={typeDropdownContents} value={typeSelection} onChange={handleTypeSelect} />
                </Col>
                <Col md={4} className="mb-3 mb-md-0">
                    <MyDropdown dropdownContents={industryDropdownContents} value={industrySelection} onChange={handleIndustrySelect} />
                </Col>
                <Col md={4}>
                    <SearchBar onSearch={handleSearch} />
                </Col>
            </Row>
            {filterCondition &&
                <div className="filter-conditions-container">
                    <Typography variant="body2" color="white">
                        {filteredData.length} 개의 결과가 있습니다.
                    </Typography>
                </div>
            }
            <RenderedUseCaseCards cardDetails={currentItems} />
            <Row className="solution-section-pagination-row">
                <Col className="solution-section-pagination-col">
                    <Pagination className="solution-section-pagination">{paginationItems}</Pagination>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default SolutionsSection;