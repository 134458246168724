import Marquee from "react-fast-marquee";
import './infiniteCarousel.css';

function CarouselItem({ imgUrl, imgTitle }) {
    return (
        <div className="carousel-card">
            <img src={imgUrl} alt={imgTitle}></img>
        </div>
    );
}

function InfiniteCarouselMarquee({ cardDetails, className }) {
    const containerClassName = `carousel-container ${className || ''}`.trim();

    return (
        <div className={containerClassName}>
            <Marquee pauseOnHover={true} gradient={false} speed={50}>
                {Object.keys(cardDetails).map((detailKey, index) => (
                    <CarouselItem
                        key={index}
                        imgUrl={cardDetails[detailKey].imgUrl}
                        imgTitle={cardDetails[detailKey].title}
                    />
                ))}
            </Marquee>
        </div>
    );
}

export default InfiniteCarouselMarquee;
