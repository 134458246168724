import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import MyAccordion from "./atoms/Accordion";

const accordionContents = [
    {
        eventKey: "1",
        title: "Hazelcast 플랫폼 엔터프라이즈 에디션과 커뮤니티 에디션의 차이점은 무엇인가요?",
        text: [
            `Hazelcast Platform Enterprise Edition은 모든 기능을 포함하는 구독 기반 버전입니다. 이는 미션 크리티컬한 프로덕션 워크로드를 위해 설계되었습니다.`,
            `Hazelcast Platform Community Edition은 무료 오픈 소스 버전이며 Hazelcast 플랫폼의 핵심 기능, 특히 빠른 데이터 저장 및 스트림 처리 엔진을 
            갖추고 있습니다. 보안, 탄력성 등을 포함하여 Enterprise Edition에 있는 많은 기능은 포함되어 있지 않습니다.`
        ]
    },
    {
        eventKey: "2",
        title: "통합 실시간 데이터 플랫폼이 무엇인가요?",
        text: [
            `통합 실시간 데이터 플랫폼은 실시간 시스템의 핵심 구성 요소들을 긴밀하게 통합된 단일 클러스터로 결합합니다. Hazelcast는 클러스터 내에서 고성능 스트림 처리 엔진과 
        초고속 데이터 저장소를 제공하여 이러한 요구 사항을 충족합니다. 덕분에 관리해야 할 변수가 줄어듭니다. 또한 Hazelcast는 상태 저장, 스냅샷을 통한 탄력성, 빠른 스트림
        결합 조회 및 디지털 통합 허브 기능 등 실시간 스트림 처리 배포에 필수적인 기능들을 제공합니다.`
        ]
    },
    {
        eventKey: "3",
        title: "왜 스트리밍 데이터 플랫폼으로 Hazelcast를 선택해야 하나요?",
        text: [
            `Hazelcast가 다른 스트리밍 데이터 플랫폼보다 선호되는 주요한 3가지 이유는 다음과 같습니다:`
        ],
        list: [
            `즉각적인 대응을 위해 설계되었습니다. 애플리케이션이 작업을 자동화하여 데이터에 잠재된 실시간 기회를 활용할 수 있습니다.`,
            `스트리밍 데이터 배포에 복잡성을 주는 독립된 기술의 숫자를 줄여서 애플리케이션 개발 및 배포를 단순화합니다.`,
            `탁월한 성능을 입증했습니다(Hasso Plattner Institute의 ESPBench 벤치마크 및 NEXMark 벤치마크 결과를 확인해보세요).`
        ]
    },
    {
        eventKey: "4",
        title: "스트림 처리란 무엇인가요?",
        text: [
            `스트림 처리는 실시간으로 데이터 스트림을 심층 분석하고 조작하는 것을 의미합니다. 여기에는 끝없는 데이터 흐름에 대한 상태 저장 집계, 윈도우 작업, 변형,
        구체화된 뷰 생성과 같은 작업을 수행하는 작업이 포함됩니다.`
        ]
    },
    {
        eventKey: "5",
        title: "이벤트 스트리밍과 스트림 처리는 어떤 관련이 있나요?",
        text: [
            `이벤트 스트리밍은 실시간 데이터 스트림을 전송하고 저장하기 위한 인프라와 수단을 제공합니다. 스트림 처리는 그 다음 단계로, 이러한 데이터 스트림을 처리하고 분석하여
        보다 의미있는 인사이트와 조치를 얻는 것입니다.`
        ]
    },
    {
        eventKey: "6",
        title: "이벤트 스트리밍과 스트림 처리의 주요 차이점은 무엇인가요?",
        text: [
            `가장 큰 차이점은 주요 목적과 기능에 있습니다. 이벤트 스트리밍은 데이터 스트림의 전송 및 지속성을 다루는 반면, 스트림 처리는 해당 스트림들의 실시간 분석
        및 변환에 중점을 둡니다.`
        ]
    },
    {
        eventKey: "7",
        title: "이벤트 스트리밍과 스트림 처리를 함께 사용할 수 있나요?",
        text: [
            `물론입니다. Apache Kafka와 같은 이벤트 스트리밍 플랫폼은 데이터 스트림을 전송하고 저장하기 위한 안정적인 기반으로 역활을 하며, Apache Flink, Spark Streaming 및 
        Hazelcast 플랫폼과 같은 스트림 처리 기술은 개발자들이 이러한 스트림에서 가지 있는 인사이트를 도출하고 처리할 수 있게 해줍니다.`
        ]
    }, {
        eventKey: "8",
        title: "스트림 처리는 기존의 배치 처리에 비해 어떤 이점을 제공하나요?",
        text: [
            `스트림 처리는 실시간 데이터 분석을 가능하게 하여 동적 데이터 변화에 대한 즉각적인 인사이트와 더 빠른 응답을 제공합니다. 반면, 기존의 배치 처리는 고정된 간격으로
        데이터를 처리하므로 인사이트와 대응에 지연이 발생합니다.`
        ]
    }, {
        eventKey: "9",
        title: "Hazelcast 플랫폼은 이벤트 스트리밍과 스트림 처리를 어떻게 지원하나요?",
        text: [
            `Hazelcast 플랫폼은 개발자들에게 실시간 데이터 스트림을 처리하기 위한 최적화된 접근 방식을 제공함으로써 탁월한 스트림 처리를 가능하게 합니다. 강력한 집계 프레임워크를
        활용하여 스트림을 효율적으로 처리하고 잠재력을 이끌어내 기업이 충분한 정보를 바탕으로 신속한 결정을 내릴 수 있도록 돕습니다.`
        ]
    }
];

const FAQSection = () => {
    return (
        <BaseCard color="baseCard-dusk">
            <Typography variant="title2" color="white" className="textShadow">
                FAQs
            </Typography>
            <MyAccordion accordionContents={accordionContents} />
        </BaseCard>
    );
};

export default FAQSection;