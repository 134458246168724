import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from './atoms/Button';
import Typography from './atoms/Typography';
import { openInNewTab } from "../utils/OpenInNewTab";
// import './atoms/cards.css';
import './SlideSection.css';

const carouselItems = [
    {
        title: "스트리밍 데이터에 즉시 대응하세요",
        description: "Hazelcast 통합 실시간 데이터 플랫폼과 함께 모든 데이터에 지연 없이, 정확하고 즉각적으로 대응하세요.",
        buttons: [
            { text: "Why Hazelcast?", variant: "primary", href: "/why-hazelcast" },
            { text: "Watch the Video", variant: "secondary", href: "https://vimeo.com/864936756/a705c74c34", newTab: true }
        ]
    },
    {
        title: "33% 운영 비용 절감 효과",
        description: "PSA Antwerp는 Hazelcast를 도입하여 실시간 비즈니스의 최적화를 통해 운영 비용을 33% 절감했습니다.",
        buttons: [
            { text: "Read the Full Story", variant: "primary", href: "https://hazelcast.com/resources/psa-antwerp-cuts-operational-costs-by-33-by-optimizing-their-business-in-real-time/", newTab: true }
        ]
    },
    // {
    //     title: "이벤트 스트림 처리를 위한 Gartner® 마켓 가이드에 기재되었습니다.",
    //     description: "Hazelcast가 보고서에서 어떻게 언급되었는지 확인해보세요. 가치 있는 Gartner 연구 보고서를 지금 바로 무료로 받아보실 수 있습니다!",
    //     buttons: [
    //         { text: "보고서 보러가기", variant: "primary", href: "https://hazelcast.com/blog/hazelcast-is-mentioned-as-a-unified-real-time-platform-in-the-gartner-market-guide-for-esp/", newTab: true }
    //     ]
    // }
    {
        title: "Hazelcast는 스트리밍의 미래를 예측합니다",
        // description: "Forrester는 2023년 4분기 The Forrester Wave™에서 Hazelcast를 강력한 성과 기업으로 선정했습니다.",
        description: "Forrester는 2023년 4분기 The Forrester Wave™에서 Hazelcast를 최우수 성능 부문에 선정했습니다.",
        buttons: [
            { text: "Get the Report", variant: "primary", href: "https://hazelcast.com/resources/forrester-wave-streaming-data-platforms-2023/", newTab: true }
        ]
    },
];

const SlideSection = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel
            className='carouselCard'
            slide
            activeIndex={index}
            onSelect={handleSelect}
            interval={3000}
            touch={true}
        >
            {carouselItems.map((item, idx) => (
                <Carousel.Item key={idx}>
                    <Carousel.Caption>
                        <div className='carousel-item-container'>

                            <div className='carousel-text-container'>
                                <Typography variant="title2" className='carousel-title textShadow'>
                                    {item.title}
                                </Typography>
                                <Typography variant="title4" className='carousel-content textShadow' color="white">
                                    {item.description}
                                </Typography>
                            </div>
                            <div className='carousel-button-container'>
                                {item.buttons && item.buttons.map((button, buttonIdx) => (
                                    <Button
                                        key={buttonIdx}
                                        variant={button.variant}
                                        href={button.href}
                                        newTab={button.newTab}
                                    >
                                        {button.text}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};


export default SlideSection;