import ErrorMessageSection from "../components/ErrorMessageSection";

const ErrorPage = () => {
    return (
        <>
            <ErrorMessageSection />
        </>
    )
};

export default ErrorPage;