import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import PipelineDiagramBefore from '../assets/images/pipeline-diagram.svg';
import PipelineDiagramAfter from '../assets/images/pipeline-diagram-after.svg';
import './SimplifyArchitectureSection.css';

const SimplifyArchitectureSection = () => {
    return (
        <BaseCard color="baseCard-ice">
            <Typography variant="title3" color="gradient-sky-deepSea" className="textShadow simplifyArchitecture-section-tit">
                아키텍처를 단순화하세요.
                </Typography>
            <div className="simplify-img-compare-container">
                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={PipelineDiagramBefore} alt="Pipeline diagram before Hazelcast" />}
                    itemTwo={<ReactCompareSliderImage src={PipelineDiagramAfter} alt="Pipeline diagram after Hazelcast" />}
                />
            </div>
            <Typography variant="body1-5">
            Hazelcast 통합 실시간 데이터 플랫폼이 제공하는 다양한 기능을 통해 데이터 기반 프로젝트의 잠재력을 최대한 활용하여 아키텍처를 단순화하세요.
            우리 플랫폼은 경쟁력 있는 애플리케이션의 구축을 지원합니다.
            </Typography>
        </BaseCard>
    );
};

export default SimplifyArchitectureSection;