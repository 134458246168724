import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import './PlatformIntroductionSection.css';
import ThumbnailImage from '../assets/images/platform-introduction-video-thumbnail.png';

const PlatformIntroductionSection = () => {

    return (
        <BaseCard color="baseCard-dusk">
            <Typography variant="title3" color="white" className="textShadow platformIntro-subtit">
                전 세계 거래의 2/3가 Hazelcast 기반 애플리케이션을 통해 전달됩니다.
            </Typography>
            <Row>
                <Col lg={6} className="platformIntro-text-container mb-4 mb-lg-0">
                    <Typography variant="body1" color="white" className="platformIntro-text">
                        최첨단 스트림 처리 엔진과 빠르게 동작하는 데이터 저장소를 겸비한 Hazelcast 통합 실시간 데이터 플랫폼은 실시간 스트리밍 데이터를 처리하고, 
                        과거 정보를 통합하여 맥락에 맞는 분석을 할 수 있게 하며, 모든 기회를 최대한 활용할 수 있게 합니다.
                    </Typography>
                </Col>
                <Col lg={6}>
                    <ReactPlayer
                        url="/videos/hazelcast_overview (720p).mp4"
                        controls={true}
                        width="100%"
                        height="100%"
                        light={
                            <img src={ThumbnailImage} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="Hazelcast Overview" />
                        }
                        playing
                    />
                </Col>
            </Row>
        </BaseCard>
    );
};

export default PlatformIntroductionSection;