import Button from '../atoms/Button';
import Card from 'react-bootstrap/Card';
import Typography from '../atoms/Typography';
import { FaCheck } from "react-icons/fa6";
import './IconCard.css';
import './FlipCard.css';
import { useContext, useState } from 'react';

function FlipCard({ cardDetail, className, dataAos }) {
    // flip 상태 관리
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div className={`card-flip ${className}`} onClick={handleFlip}>
            <div className={`card-flip-inner ${isFlipped ? 'is-flipped' : 'is-not-flipped'}`}>
                <div className='card-flip-front'>
                    <Card className='flip-card'>
                        <Card.Body className='imgCard-body flipCard-body'>
                            <Card.Img variant="top" src={cardDetail.imgUrl} className='flip-icon-container' />
                            <Typography variant="title6">
                                {cardDetail.title}
                            </Typography>
                        </Card.Body>
                    </Card>
                </div>
                <div className='card-flip-back'>
                    <Card className='flip-card'>
                        <Card.Body className='imgCard-body flipCard-body flipCard-body-back'>
                            <ul className='flip-card-ul'>
                                {cardDetail.text && Object.values(cardDetail.text).map((text, index) => (
                                    // <Typography key={index} variant="body3" style={{ marginBottom: '10px' }}>
                                    //     <FaCheck color='#10A4B3' style={{ fontSize: '18px', marginRight: '2%' }} />
                                    //     {text}
                                    // </Typography>
                                    <li key={index} className='flip-card-list'>
                                        <FaCheck color='#10A4B3' className='flip-card-list-icon' />
                                        <Typography variant="body3" style={{ marginBottom: '10px', flex: '1' }}>
                                            {text}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div >
    );
}

export default FlipCard;