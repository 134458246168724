import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Link } from "react-router-dom";
import './ErrorMessageSection.css';

const ErrorMessageSection = () => {
    return (
        <BaseCard color="baseCard-white" baseClassName="error-sec-card">
            <Typography variant="title3" color="black">
                404 에러
            </Typography>
            <Typography variant="body3">
            잘못된 URL입니다. <br />
            <Link to="/" className="error-sec-link">홈으로 돌아가기</Link>
            </Typography>
        </BaseCard>
    );
};

export default ErrorMessageSection;