import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Typography from '../atoms/Typography';
import { MdOutlineArrowOutward } from "react-icons/md";
import './UseCaseCard.css';

const UseCaseCard = ({ cardDetail }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='useCase-card-container'>
            <Card className={`useCase-card ${isHovered ? 'card-expanded' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // 모바일 기기 터치
                onTouchStart={() => setIsHovered(true)}
                onTouchEnd={() => setIsHovered(false)}
            >
                <Card.Body className="useCase-card-body">
                    <Typography variant="title5">
                        {cardDetail.title}
                    </Typography>
                    <Typography variant="body3" className="text-clamp">
                        {cardDetail.text}
                    </Typography>
                    <a href={cardDetail.href} target='_blank' rel='noopener noreferrer' className="useCase-card-link">
                        <Typography variant="body4">더 알아보기 <MdOutlineArrowOutward color='black' /></Typography>
                    </a>
                </Card.Body>
            </Card>
        </div>
    );
};

export default UseCaseCard;