import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col } from "react-bootstrap";
import PieChartExample from "./atoms/PieChart";
import './TwoThirdSection.css';

const innerPieData = [
    { name: 'Group A', value: 300, color: '#C91268' },
    // { name: 'Group A', value: 1, color: 'transparent' },
    { name: 'Group B', value: 300, color: '#C91268' },
    { name: 'Group B', value: 300, color: 'transparent' },
];
const outerPieData = [
    { name: 'A1', value: 100 }
];

const TwoThirdSection = () => {
    return (
        <BaseCard color="baseCard-ice" bodyClassName="twoThirdSection-card-body">
            <Row>
                <Col md={4}>
                    <PieChartExample
                        innerPieData={innerPieData}
                        outerPieData={outerPieData}
                        width={400}
                        height={400}
                        innerPieRadius={90}
                        outerPieRadius_start={98}
                        outerPieRadius_end={100}
                        outerPieColor={"#C91268"}
                        innerPieColor={"#C91268"}
                        innerPieLabel={false}
                        outerPieLabel={false}
                        cx="60%"
                        cy="50%"
                    />
                </Col>
                <Col md={8} className="twoThird-text">
                    <Typography variant="title3">
                        <Typography variant="title3" color="gradient-raspberry-carrot">
                            전 세계 거래의 2/3&nbsp;
                        </Typography>
                        가 Hazelcast 기반 애플리케이션을 통해 전달됩니다.
                    </Typography>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default TwoThirdSection;