import Accor from '../assets/images/client-logo/global/logo-slider-color_accor.svg';
import BnpParibas from '../assets/images/client-logo/global/logo-slider-color_bnp-paribas.svg';
import CoxAutomotive from '../assets/images/client-logo/global/logo-slider-color_cox-automotive.svg';
import DeutscheBank from '../assets/images/client-logo/global/logo-slider-color_deutsche-bank.svg';
import Hsbc from '../assets/images/client-logo/global/logo-slider-color_hsbc.svg';
import JpMorgan from '../assets/images/client-logo/global/logo-slider-color_jp-morgan.svg';
import Nissan from '../assets/images/client-logo/global/logo-slider-color_nissan.svg';
import Target from '../assets/images/client-logo/global/logo-slider-color_target.svg';
import TdBank from '../assets/images/client-logo/global/logo-slider-color_td-bank.svg';
import Volvo from '../assets/images/client-logo/global/logo-slider-color_volvo.svg';
import Dominos from '../assets/images/client-logo/global/logo-slider_dominos.svg';

const globalCardDetails = {
    0: {
        imgUrl: Accor,
        title: "Accor"
    },
    1: {
        imgUrl: BnpParibas,
        title: "BnpParibas"
    },
    2: {
        imgUrl: CoxAutomotive,
        title: "CoxAutomotive"
    },
    3: {
        imgUrl: DeutscheBank,
        title: "DeutscheBank"
    },
    4: {
        imgUrl: Hsbc,
        title: "Hsbc"
    },
    5: {
        imgUrl: JpMorgan,
        title: "JpMorgan"
    },
    6: {
        imgUrl: Nissan,
        title: "Nissan"
    },
    7: {
        imgUrl: Target,
        title: "Target"
    },
    8: {
        imgUrl: TdBank,
        title: "TdBank"
    },
    9: {
        imgUrl: Volvo,
        title: "Volvo"
    },
    10: {
        imgUrl: Dominos,
        title: "Dominos"
    },
}

export default globalCardDetails;