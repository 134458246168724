import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import ThumbnailImage from '../assets/images/platform-introduction-video-thumbnail.png';
import './RealTimeSection.css';

const RealTimeSection = () => {
    return (
        <BaseCard color="baseCard-dusk" baseClassName="realtime-section">
            <Typography variant="title3" color="white" className="realtime-tit">
                Hazelcast는 비즈니스에 실시간 기능을 부여해서 모든 데이터를 기회로 전환합니다.
            </Typography>
            <Row>
                <Col lg={6} className="realtime-text-container mb-4 mb-lg-0">
                    <Typography variant="body2" color="white" className="realtime-text">
                        우리의 통합 실시간 데이터 플랫폼은 스트림 처리와 빠른 데이터 저장소를 결합하여 스트리밍 데이터에 즉시 대응할 수 있습니다.
                    </Typography>
                    <Typography variant="body2" color="white" className="realtime-text">
                        우리의 플랫폼은 온-프레미스와 클라우드 환경 모두에서 즉각적인 대응을 위해 애플리케이션 데이터 구조를 자동화하며, 간소화하고 개선합니다. 이를 통해
                        우리의 고객들은 더 빠른 출시 속도와 향상된 투자 수익률(ROI)로 더 효율적으로 운영할 수 있게 됩니다. 데이터 아키텍처를 조직의 성장에 맞춰 확장할 수
                        있어 미래에 대비할 수 있게 합니다.
                    </Typography>
                    <Typography variant="body2" color="white" className="realtime-text">
                        시장의 리더로서, 우리는 실시간 데이터의 중요성과 잠재력을 강력히 주도하며, 끊임없는 혁신으로 활기찬 생태계를 선도합니다. 그 결과 다양한 산업 분야
                        전반에서 기업들에게 선호받는 데이터 파트너가 되었습니다.
                    </Typography>
                </Col>
                <Col lg={6} className="react-player-container">
                    <ReactPlayer
                        url="/videos/hazelcast_overview (720p).mp4"
                        controls={true}
                        width="100%"
                        height="100%"
                        light={
                            <img src={ThumbnailImage} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="Hazelcast Overview" />
                        }
                        playing
                    />
                </Col>
            </Row>
        </BaseCard>
    );
};

export default RealTimeSection;