import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { SiNaver } from "react-icons/si";
import NaverIcon from "../components/atoms/NaverIcon";
import './footer.css';


const Footer = () => {
    // 네이버 아이콘 호버 시 색상 변경
    const [naverIconColor, setNaverIconColor] = useState('#00ADBB');

    return (
        <footer>
            <Container>
                <Row className="justify-content-between">
                    <Col xs={12} md={3} className="hazelcast-korea mb-4 mb-md-0">
                        <h3>Hazelcast Korea</h3>
                        <p>A1309, D-Tech Tower, 33, Gwacheon-daero 7-gil, Gwacheon-si, Gyeonggi-do, Republic of Korea</p>
                        <div className="hazelcast-korea-contact">
                            <p>
                                <b>Phone: </b>+82 2 6390 6052
                            </p>
                            <p>
                                <b>Email: </b>info@hazelcast.co.kr
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={3} className="useful-links mb-4 mb-md-0">
                        <h5>Useful Links</h5>
                        <a href="#"><p><span><FaChevronRight /></span> Top</p></a>
                        <p><span><FaChevronRight /></span><Link to="/why-hazelcast"> Why Hazelcast</Link></p>
                        <a href="https://hazelcast.com/brand-assets/" target="_blank" rel="noreferrer noopener"><p><span><FaChevronRight /></span> Brand Assets</p></a>
                    </Col>
                    <Col xs={12} md={3} className="get-hazelcast mb-4 mb-md-0">
                        <h5>Get Hazelcast</h5>
                        <a href="mailto:info@hazelcast.co.kr?subject=Hazelcast 문의&body=문의 기관:%0D%0A문의 제품: Hazelcast IMDG%0D%0A문의 내용:%0D%0A연락처:">
                            <p>
                                <span><FaChevronRight /></span> Get Hazelcast IMDG
                            </p>
                        </a>
                        <a href="mailto:info@hazelcast.co.kr?subject=Hazelcast 문의&body=문의 기관:%0D%0A문의 제품: Hazelcast Jet%0D%0A문의 내용:%0D%0A연락처:">
                            <p>
                                <span><FaChevronRight /></span> Get Hazelcast JET
                            </p>
                        </a>
                    </Col>
                    <Col xs={12} md={3} className="sns">
                        <h5>SNS</h5>
                        <a href="https://www.facebook.com/profile.php?id=100094049776356&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><span className="facebook-icon"><AiFillFacebook /></span></a>
                        <a href="https://www.instagram.com/hazelcast__korea?igsh=MXE4N2Q2ZWswMzhqOA==" target="_blank" rel="noopener noreferrer"><span className="instagram-icon"><AiFillInstagram /></span></a>
                        {/* <a
                            href="https://blog.naver.com/hazelcast"
                            target="_blank"
                            rel="noopener noreferrer"
                            onMouseEnter={() => setNaverIconColor('#03C75A')}
                            onMouseLeave={() => setNaverIconColor('#00ADBB')}
                        >
                            <span className="naver-icon">
                                <NaverIcon color={naverIconColor} />
                            </span>
                        </a> */}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;