import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Typography from '../atoms/Typography';

const MobileNavigation = ({ menuItems, isNavExpanded, setIsNavExpanded }) => {
    return (
        <Row className="mobile-menu">
            <Col md={12}>
                <div className={isNavExpanded ? 'mobile-nav active' : 'mobile-nav'}>
                    <nav>
                        <ul>
                            {menuItems.map((menuItem, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={menuItem.href}
                                        onClick={() => setIsNavExpanded(false)}
                                        className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
                                    >
                                        <Typography variant="title5">
                                            {menuItem.title}
                                        </Typography>
                                    </NavLink>
                                    {/* {menuItem.items && (
                                        <ul>
                                            {menuItem.items.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <NavLink
                                                        to={subItem.href}
                                                        onClick={() => setIsNavExpanded(false)}
                                                        className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
                                                    >
                                                        {subItem.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )} */}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </Col>
        </Row>
    );
};

export default MobileNavigation;
