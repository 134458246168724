import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import logoImage from '../assets/images/logo.webp';
import './header.css';
import { IoMenu, IoClose } from "react-icons/io5";
import Navigation from './Navigation';
import MobileNavigation from '../components/molecules/MobileNavigation';

function Header() {
    const menuItems = [
        {
            id: 1,
            title: "HOME",
            href: "/"
        },
        {
            id: 2,
            title: "About Us",
            href: "/about-us",
            items: [
                { name: "메뉴 1", href: "/first" },
                { name: "메뉴 2", href: "/second" },
                {
                    name: "메뉴 3", subItems: [
                        { name: "하위 메뉴 1", href: "/sub-first" },
                        { name: "하위 메뉴 2", href: "/sub-second" }
                    ]
                }
            ],
        },
        {
            id: 3,
            title: "Why Hazelcast?",
            href: "/why-hazelcast",
            items: [
                { name: "왜 헤이즐캐스트인가?", href: "/why-hazelcast" },
                { name: "About Us", href: "/about-us" },
                { name: "고객", href: "/customers" }
            ],
        },
        {
            id: 4,
            title: "Solutions",
            href: "/solutions",
            items: [
                { name: "모든 솔루션", href: "/solutions-all" },
                {
                    name: "업종 별", subItems: [
                        { name: "금융 서비스", href: "/financial-services" },
                        { name: "소매 금융", href: "/retail-banking" },
                        { name: "소매/전자상거래", href: "/e-commerce" },
                        { name: "공급망/물류", href: "/supply-chain-logistics" },
                        { name: "헬스케어", href: "/health-care" }
                    ]
                },
                {
                    name: "사용 사례 별", subItems: [
                        { name: "결제 처리", href: "payment-processing" },
                        { name: "사기 탐지", href: "fraud-detection" },
                        { name: "실시간 제안", href: "real-time-offers" }
                    ]
                },
                {
                    name: "아키텍처 별", subItems: [
                        { name: "캐싱", href: "caching" },
                        { name: "스트림 처리", href: "stream-processing" },
                        { name: "분산 컴퓨팅", href: "distributed-computing" }
                    ]
                }
            ],
        },
        {
            id: 5,
            title: "Platform",
            href: "/platform",
            items: [
                { name: "플랫폼 개요", href: "/platform-overview" },
                { name: "자체 관리형 배포", href: "/self-managed" }
            ],
        },
    ];

    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const handleToggleNav = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    // 992px 이상에서 모바일 네비게이션 닫기
    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 992px)');

        const handleMediaQueryChange = (event) => {
            if (event.matches) {
                setIsNavExpanded(false);
            }
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);

    return (
        <header>
            <Container>
                <Row className="align-items-center justify-content-between">
                    <Col xs={5} md={4}>
                        <Navbar.Brand as={Link} to="/" onClick={() => window.scrollTo(0, 0)}>
                            <img src={logoImage} height="63px" alt='logo' />
                        </Navbar.Brand>
                    </Col>
                    <Navigation menuItems={menuItems} />
                    <Col xs={2} sm={1} className="d-lg-none">
                        <button onClick={handleToggleNav} className='mobile-nav-icon'>
                            <span>
                                {isNavExpanded ? <IoClose color='black' /> : <IoMenu color='black' />}
                            </span>
                        </button>
                    </Col>
                </Row>
                {isNavExpanded && (
                    <>
                        <div className='mobile-overlay' onClick={handleToggleNav} />
                        <MobileNavigation menuItems={menuItems} isNavExpanded={isNavExpanded} setIsNavExpanded={setIsNavExpanded} />
                    </>
                )}
            </Container>
        </header>
    );
}

export default Header;