import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import ImageSection from "./atoms/ImageSection";
import HazelcastUnifiedRealTimeDataPlatform from '../assets/images/HazelcastUnifiedRealTimeDataPlatform.png';
import './UnifiedPlatformSection.css';

const UnifiedPlatformSection = () => {
    return (
        <BaseCard color="baseCard-dusk">
            <Typography variant="title3" color="white" className="unifiedPlatform-section-tit textShadow">
                Hazelcast 통합 실시간 데이터 플랫폼
            </Typography>
            <div className="unifiedPlatform-img-container">
                <ImageSection
                    image={HazelcastUnifiedRealTimeDataPlatform}
                    style={{ maxHeight: '550px' }}
                    imageDescription="Instant-Action"
                />
            </div>
            <Typography variant="body1-5" color="white">
                Hazelcast를 이용하면 여러 소프트웨어 구성 요소를 통합하는 복잡한 과정 없이 실시간 데이터의 잠재력을 100% 활용할 수 있습니다.
                우리의 통합 플랫폼은 요청 증가, 예기치 않은 부하 급증, 다양한 구성 요소에서의 하드웨어 장애, 다운타임 등에 대처하며 지속적인 관리 작업을 수행합니다.
                또한 이는 기존의 인프라에 통합되기 때문에 기술을 전면 교체하지 않고도 기존의 애플리케이션에 data in motion에 대한 즉각적인 대응 능력을 부여할 수 있습니다.
            </Typography>
        </BaseCard>
    );
};

export default UnifiedPlatformSection;