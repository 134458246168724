import { Helmet } from "react-helmet-async";
import Typography from "../components/atoms/Typography";
import MyBreadcrumb from "../components/molecules/Breadcrumb";
import PageTitle from "../components/molecules/PageTitle";
import PlatformIntroductionSection from "../components/PlatformIntroductionSection";
import StreamingDataSection from "../components/StreamingDataSection";
import MilestonesSection from "../components/MilestonesSection";

const breadcrumbItems = [
    {
        text: 'Home',
        path: '/'
    },
    {
        text: '왜 헤이즐캐스트인가',
        path: '/why-hazelcast'
    }
]

const WhyHazelcastPage = () => {
    return (
        <>
            <Helmet>
                <title>왜 헤이즐캐스트인가 | Hazelcast Korea</title>
                <meta name="description" content='전 세계 거래의 2/3가 Hazelcast 기반 애플리케이션을 통해 이루어집니다. 최첨단 스트림 처리 엔진과 초고속 데이터 저장소의 결합을 통해 모든 기회를 활용하고 리스크에 대응할 수 있습니다.' />
                <meta property='og:description' content='전 세계 거래의 2/3가 Hazelcast 기반 애플리케이션을 통해 이루어집니다. 최첨단 스트림 처리 엔진과 초고속 데이터 저장소의 결합을 통해 모든 기회를 활용하고 리스크에 대응할 수 있습니다.' />
                <meta property='og:url' content='https://hazelcastkorea.com/why-hazelcast' />
            </Helmet>
            <MyBreadcrumb breadcrumbItems={breadcrumbItems} />
            <PageTitle colClassName="whyHazelcast-pagetit">
                Hazelcast를 사용하면 모든 비즈니스의&nbsp;
                <Typography variant="title1" color="gradient-sky-lime">
                    실시간 잠재력
                </Typography>
                을 실현할 수 있습니다.
            </PageTitle>

            <PlatformIntroductionSection />
            <StreamingDataSection />
            <MilestonesSection />
        </>
    );
};

export default WhyHazelcastPage;