import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Typography from '../atoms/Typography';
import Button from '../atoms/Button';
import { MdOutlineArrowOutward } from "react-icons/md";
import './ResourceCard.css';

const ResourceCard = ({ variant, subTitle, title, text, buttonText, buttonLink, link, secondResource, className }) => {

    return (
        <Card className={`${variant === 'large' ? 'large-card' : 'small-card'} ${className}`} data-aos="flip-left">
            <Card.Body>
                {subTitle && <Typography variant="subTitle1">{subTitle}</Typography>}
                <Typography variant={variant === 'large' ? 'title4' : 'title5'} className="firstResource-title">
                    {title}
                </Typography>
                {text &&
                    <Typography variant="body2">
                        {text}
                    </Typography>}
                {link && <a href={link} target='_blank' rel="noopener noreferrer"><Typography variant="body2">더 알아보기 <MdOutlineArrowOutward /></Typography></a>}
                {buttonText && <div><Button variant="btnWhite" href={buttonLink} newTab={true}>
                    <Typography variant="body3">
                        {buttonText}
                    </Typography>
                </Button></div>}
                {secondResource && <hr />}
                {secondResource && (
                    <>
                        {secondResource.title && <Typography variant="subTitle1">{secondResource.subTitle}</Typography>}
                        <Typography variant="title4" className="secondResource-title sky">{secondResource.title}</Typography>
                        {secondResource.text && <Typography variant="body2">{secondResource.text}</Typography>}
                        <a href={secondResource.link} target='_blank' rel="noopener noreferrer"><Typography variant="body2">더 알아보기 <MdOutlineArrowOutward /></Typography></a>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default ResourceCard;

export const renderedTabContent = (tabData, key) => {
    const largeCards = tabData[key].filter(data => data.variant === "large");
    const smallCards = tabData[key].filter(data => data.variant === "small");

    return (
        <>
            <Row className="h-100">
                {largeCards.length > 0 && (
                    <Col lg={4} className="d-flex">
                        <ResourceCard
                            {...largeCards[0]}
                            className="large-card-first w-100"
                        />
                    </Col>
                )}
                <Col lg={4} className="d-flex flex-column justify-content-between">
                    {smallCards.map((card, index) => (
                        <ResourceCard
                            {...card}
                            key={`small-${index}`}
                            // className="flex-grow-1"
                            className={`small-card-${index + 1} flex-grow-1`}
                        />
                    ))}
                </Col>
                {largeCards.length > 1 && (
                    <Col lg={4} className="d-flex">
                        <ResourceCard
                            {...largeCards[1]}
                            className="large-card-second w-100" />
                    </Col>
                )}
            </Row>

        </>
    );
};