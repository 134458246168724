import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import ImageSection from "./atoms/ImageSection";
import { Row, Col } from "react-bootstrap";
import Enhance from '../assets/images/enhance.png';
import './EnhanceArchitectureSection.css';

const EnhanceArchitectureSection = () => {
    return (
        <BaseCard color="baseCard-dusk">
            <Row>
                <Col lg={{ span: 3, offset: 1 }} sm={4} className="mb-4 mb-sm-0 enhance-section-col-img">
                    <ImageSection
                        image={Enhance}
                        style={{ maxHeight: '300px' }}
                        imageDescription="Enhance"
                    />
                </Col>
                <Col lg={{ span: 7, offset: 1 }} sm={8} className="enhance-section-col">
                    <div className="enhance-section-text-body">
                        <Typography variant="title4" color="lime">
                            미래 보장형 성장을 위한 데이터 아키텍처 강화
                        </Typography>
                        <Typography variant="body1-5" color="white">
                            Hazelcast 플랫폼은 다양한 사용 사례를 지원하며 장애 및 재해 복구를 위한 낮은 RPO와 RTO로 고가용성을 보장합니다. 계획된 다운타임을 최소화하고
                            애플리케이션 업그레이드 중의 다운타임 제로와 데이터 손실 제로를 보장합니다.
                        </Typography>
                    </div>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default EnhanceArchitectureSection;