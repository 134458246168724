import { Helmet } from "react-helmet-async";
import Typography from "../components/atoms/Typography";
import MyBreadcrumb from "../components/molecules/Breadcrumb";
import PageTitle from "../components/molecules/PageTitle";
import InstantActionSection from "../components/InstantActionSection";
import TwoThirdSection from "../components/TwoThirdSection";
import UnifiedPlatformSection from "../components/UnifiedPlatformSection";
import SimplifyArchitectureSection from "../components/SimplifyArchitectureSection";
import AutomateArchitectureSection from "../components/AutomateArchitectureSection";
import StreamlineArchitectureSection from "../components/StreamlineArchitectureSection";
import EnhanceArchitectureSection from "../components/EnhanceArchitectureSection";
import PlatformFeaturesSection from "../components/PlatformFeaturesSection";
import FAQSection from "../components/FAQSection";

const breadcrumbItems = [
    {
        text: 'Home',
        path: '/'
    },
    {
        text: '플랫폼 개요',
        path: '/platform'
    }
]

const PlatformOverviewPage = () => {
    return (
        <>
            <Helmet>
                <title>플랫폼 개요 | Hazelcast Korea</title>
                <meta name="description" content='Hazelcast는 기존의 인프라에 통합되어 아키텍처를 간소화하고, 가용성을 향상시킵니다. 프로젝트의 잠재력과 비용 효율을 극대화할 수 있습니다.' />
                <meta property='og:description' content='Hazelcast는 기존의 인프라에 통합되어 아키텍처를 간소화하고, 가용성을 향상시킵니다. 프로젝트의 잠재력과 비용 효율을 극대화할 수 있습니다.' />
                <meta property='og:url' content='https://hazelcastkorea.com/platform' />
            </Helmet>
            <MyBreadcrumb breadcrumbItems={breadcrumbItems} />
            <PageTitle colClassName="whyHazelcast-pagetit">
                Hazelcast&nbsp;
                <Typography variant="title1" color="gradient-sky-lime ">
                    통합 실시간 데이터 플랫폼
                </Typography>
            </PageTitle>

            <InstantActionSection />
            <TwoThirdSection />
            <UnifiedPlatformSection />
            <SimplifyArchitectureSection />
            <AutomateArchitectureSection />
            <StreamlineArchitectureSection />
            <EnhanceArchitectureSection />
            <PlatformFeaturesSection />
            <FAQSection />
        </>
    );
};

export default PlatformOverviewPage;