import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import ImageCard from "./molecules/ImageCard";
import { Row, Col } from "react-bootstrap";
import ImgUrl from '../assets/images/DALL·E 2024-03-27 11.03.14 - Create a small-size image that visualizes the concept of data streamline and software architecture in a simplistic manner. The design should not be to.webp';
import './StreamlineArchitectureSection.css';

const cardDetail = {
    imgUrl: ImgUrl,
    title: "Marko Topolnik / 2021/3/17",
    text: "초당 10억 이벤트, 밀리초 단위 지연 시간 : Giga 단위 스트리밍 애널리틱스",
    href: "https://hazelcast.com/blog/billion-events-per-second-with-millisecond-latency-streaming-analytics-at-giga-scale/"
}

const StreamlineArchitectureSection = () => {
    return (
        <BaseCard color="baseCard-ice">
            <Row>
                <Col lg={7} md={7} className="streamline-section-col mb-4 mb-md-0">
                    <div className="streamline-section-text-body">
                        <Typography variant="title4">
                            효율, 속도 및 ROI를 위한 데이터 아키텍처 간소화
                        </Typography>
                        <Typography variant="body1-5">
                            Hazelcast 플랫폼의 간결한 하드웨어 공간은 증가하는 워크로드를 쉽게 처리하는 동시에 초고속 성능과 비용 효율을 제공합니다.
                        </Typography>
                    </div>
                </Col>
                <Col lg={{ span: 4, offset: 1 }} md={5} className="streamline-section-col-img">
                    <ImageCard cardDetail={cardDetail} className="streamline-section-imgCard" onClick={cardDetail.href} />
                </Col>
            </Row>
        </BaseCard>
    );
};

export default StreamlineArchitectureSection;