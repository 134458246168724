import BaseCard from './atoms/BaseCard';
import Typography from './atoms/Typography';
import globalCardDetails from './global-carousel-config';
import localCardDetails from './local-carousel-config';
import InfiniteCarouselMarquee from './molecules/InfiniteCarouselMarquee';
import './ClientsSection.css';

function ClientsSection() {
    return (
        <BaseCard color="baseCard-dusk" baseClassName='carouselCard-base' bodyClassName='carouselCard-body' dataAos="fade-up">
            <Typography variant="title2" color="white" style={{ textShadow: '2px 2px 3px rgba(0, 0, 0, 0.25)' }}>
                GLOBAL CLIENTS
            </Typography>
            <InfiniteCarouselMarquee cardDetails={globalCardDetails} className="global-clients-container" />
            <Typography variant="title2" color="white" style={{ textShadow: '2px 2px 3px rgba(0, 0, 0, 0.25)' }}>
                LOCAL CLIENTS
            </Typography>
            <InfiniteCarouselMarquee cardDetails={localCardDetails} className="local-clients-container" />
        </BaseCard>
    );
}

export default ClientsSection;