import { Helmet } from "react-helmet-async";
import Typography from "../components/atoms/Typography";
import MyBreadcrumb from "../components/molecules/Breadcrumb";
import PageTitle from "../components/molecules/PageTitle";
import RealTimeSection from "../components/RealTimeSection";
import OurValuesSection from "../components/OurValuesSection";
import OurStorySection from "../components/OurStorySection";
import CustomerObsessionSection from "../components/CustomerObsessionSection";

const breadcrumbItems = [
    {
        text: 'Home',
        path: '/'
    },
    {
        text: 'About Us',
        path: '/about-us'
    }
]

const AboutUsPage = () => {
    return (
        <>
            <Helmet>
                <title>About Us | Hazelcast Korea</title>
                <meta name="description" content='초고속 데이터 저장소와 스트림 처리의 결합을 통해 대규모 데이터를 실시간으로 처리합니다. Hazelcast는 기술력과 신뢰도를 바탕으로 글로벌 기관과 금융 회사, 유명 브랜드들의 선택을 받아왔습니다. ' />
                <meta property='og:description' content='초고속 데이터 저장소와 스트림 처리의 결합을 통해 대규모 데이터를 실시간으로 처리합니다. Hazelcast는 기술력과 신뢰도를 바탕으로 글로벌 기관과 금융 회사, 유명 브랜드들의 선택을 받아왔습니다.' />
                <meta property='og:url' content='https://hazelcastkorea.com/about-us' />
            </Helmet>
            <MyBreadcrumb breadcrumbItems={breadcrumbItems} />
            <PageTitle colClassName="whyHazelcast-pagetit">
                Hazelcast: 모든 비즈니스에 적용되는&nbsp;
                <Typography variant="title1" color="gradient-raspberry-carrot">
                    'Real-time'
                </Typography>
            </PageTitle>

            <RealTimeSection />
            <OurValuesSection />
            <OurStorySection />
            <CustomerObsessionSection />
        </>
    );
};

export default AboutUsPage;