import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import MyTab from "./atoms/Tab";
import './PlatformFeaturesSection.css';

const tabContents = [
    {
        eventKey: "1",
        label: "분산 컴퓨팅",
        title: "구축하기 쉬운 분산 애플리케이션으로 지속적으로 데이터를 처리합니다.",
        text: [
            `많은 최신 데이터 인프라에서는 비즈니스에 대한 실시간 보기를 제공하기 위해 데이터를 지속적으로 업데이트해야 합니다. 그러나 올바른
            애플리케이션 프레임워크가 없으면 데이터를 최신 상태로 유지하는 분산, 병렬화, 확장 가능, 복원력 및 보안 애플리케이션을 작성하기가 어렵습니다.`,
            `Hazelcast 플랫폼 분산 컴퓨팅 엔진을 사용하면 클러스터 리소스를 효율적으로 활용하는 대규모 데이터 처리가 가능합니다. 데이터를 처리하는 
            애플리케이션을 작성하면 Hazelcast 클러스터가 작업 조정을 처리하므로 조정 코드가 아닌 높은 가치의 코드에 집중할 수 있습니다.`
        ]
    },
    {
        eventKey: "2",
        label: "캐싱",
        title: "단일 플랫폼의 다른 많은 기능 중 하나로서 데이터에 더 빠르게 액세스할 수 있습니다.",
        text: [
            `데이터 액세스 속도를 높이기 위해 캐시가 필요하신가요? Hazelcast 플랫폼은 속도, 규모, 탄력성 및 보안 요구 사항을 충족하는 데 필요한 모든 캐싱 기능을 제공합니다. 
            저희는 전 세계에서 가장 까다로운 기업들로부터 검증 받은 실적을 보유하고 있습니다.`,
            `캐싱 외에도 Hazelcast 플랫폼은 데이터 기반 전략에 필요한 기능을 제공합니다. Hazelcast 플랫폼의 분산 컴퓨팅 및 스트림 처리 기능이 
            어떻게 기술적 부채 방지를 돕는지 탐색해보세요.`
        ]
    },
    {
        eventKey: "3",
        label: "스트림 처리 엔진",
        title: "이동 중인 데이터를 처리하는 핵심 엔진",
        text: [
            `Hazelcast 플랫폼은 어떤 규모에서든 이벤트 스트림 처리와 빠른 배치 처리를 가능하게 합니다. 데이터베이스, 데이터 레이크, 애플리케이션, 장치
            및 Apache Kafka, Apache Pulsar, AWS Kinesis, RabbitMQ와 같은 메시지 브로커로부터 실시간 데이터를 검색합니다. 그런 다음 원시 대용량 데이터 스트림을 
            비즈니스 이벤트 및 실행 가능한 인사이트로 변환하여 애플리케이션, 대시보드 및 데이터베이스에서 쉽게 사용할 수 있도록 합니다.`
        ]
    },
    {
        eventKey: "4",
        label: "고밀도 메모리 저장소",
        title: "복잡성은 줄이고 성능을 저하시키는 가비지 수집을 방지합니다.",
        text: [
            `고성능 실시간 시스템은 Java 가비지 수집기(GC)로 인해 발생하는 중단을 감당할 수 없습니다. 가비지 수집은 JVM(Java Virtual Machine)에 대량의 메모리가 
            할당될 때 가장 크게 성능에 영향을 줍니다. 이를 방지하기 위해 DevOps 팀은 일반적으로 노드당 더 적은 데이터로 더 많은 노드를 배포하므로 GC 중단을 피하기 위해 관리 복잡성이 발생합니다.`,
            `Hazelcast 고밀도 메모리 저장소 기능은 GC 중단을 방지하기 위해 오프힙 메모리를 활용하는 비Java 메모리 할당자입니다. 
            이는 전체 시스템 성능을 향상시키는 동시에 노드 수를 줄여 유지 관리 오버헤드를 낮추는 데 도움이 됩니다.`
        ]
    },
    {
        eventKey: "5",
        label: "고속 메모리 저장소",
        title: "실시간 배포를 위한 다목적 엔진",
        text: [
            `Hazelcast는 일련의 네트워크/클러스터 컴퓨팅 리소스를 연결하여 애플리케이션이 데이터 구조를 공유하고 클러스터에서 병렬화된 워크로드를 실행할 수 있도록 합니다.`,
            `가장 큰 장점은 속도로, 이는 수십억 대의 모바일, IoT 장치 및 기타 소스가 지속적으로 데이터를 스트리밍하는 오늘날 매우 중요한 가치입니다. 모든 관련 정보가 RAM에 있기 때문에
            트랜잭션 처리를 위해 네트워크를 거쳐 원격 저장소로 이동할 필요가 없습니다. 이 속도 차이는 큽니다. - 복잡한 트랜잭션이 초당 수백만 번 수행된다고 했을 때 수 분이 걸릴 것이
            밀리초 미만으로 줄어듭니다.`
        ]
    },
    {
        eventKey: "6",
        label: "커넥터",
        title: "기존 데이터 플랫폼에 즉시 연결 가능합니다.",
        text: [
            `이 플랫폼을 사용하면 다양한 애플리케이션과 데이터 시스템을 손쉽게 통합할 수 있으므로 추가적인 코드가 필요하지 않습니다. Kafka Connect 생태계의 모든 커넥터에 대한
            지원을 포함한 광범위한 사전 구축 커넥터를 사용하여 데이터 아키텍처를 한 단계 끌어올리세요. 이는 어떤 규모에서건 신속한 현대화와 강력한 통합을 가능하게 합니다.`
        ]
    },
    {
        eventKey: "7",
        label: "SQL 쿼리",
        title: "Data in motion과 data at rest 모두에 대해 SQL을 활용하세요.",
        text: [
            `SQL 지원은 쿼리 실행을 위한 친숙한 인터페이스를 제공하며, 다음의 이점을 가집니다.`
        ],
        list: [
            { 
                listTitle: `업계 표준 쿼리`,
                listText: `기존 Hazelcast Predicate 기반 디자인과 동일한 쿼리 특이성을 유지하면서 업계 표준 접근 방식을 사용하여 대량의 데이터를 쿼리합니다.`
            },
            { 
                listTitle: `고성능 인덱싱`,
                listText: `새로운 고성능 동시 오프 힙 B+ 트리 인덱스를 활용하여 쿼리 성능을 최적화합니다.`
            },
            { 
                listTitle: `고급 쿼리 최적화`,
                listText: `고급 쿼리 최적화 기술을 활용하여 쿼리 효율성을 향상합니다.`
            }
        ]
    },
    {
        eventKey: "8",
        label: "매니지먼트 센터",
        title: "Hazelcast 배포 관리 및 모니터링",
        text: [
            `Hazelcast 매니지먼트 센터는 배포 방식에 관계 없이 Hazelcast 클러스터 전체에서 스크립트(Javascript, Groovy 등)와 명령을 실행하기 위한 스크립팅 및
            콘솔 모듈을 제공합니다. 시각적 도구는 데이터 흐름을 분석하고 실시간으로 병목 현상을 식별하는 데 도움을 줍니다. 또한 개발자들에게 실시간으로 클러스터 인사이트를 제공합니다.`,
            `매니지먼트 센터는 개발 과정에 심도있는 인사이트를 제공합니다. 프로덕션에서는 IT 운영에 사용하거나 REST 및 JMX를 통해 엔터프라이즈 모니터링 도구와 통합할 수 있습니다.
            Hazelcast의 상용 라이센스를 사용하면 어떤 규모의 클러스터라도 매니지먼트 센터를 사용하여 모니터링 할 수 있습니다.`
        ]
    },
    {
        eventKey: "9",
        label: "보안 제품군",
        title: "역할 기반 및 엑세스 제어 및 암호화로 데이터를 보호하세요.",
        text: [
            `Hazelcast는 양방향 TLS 암호화, X509 인증서를 사용한 상호 인증, 표준 JAAS(Java Authentication and Authorization Service)를 통한 역할 기반 인증을 통해 업계 최고의
            보안을 보장합니다. 애플리케이션에 보안을 원활하게 통합하여 민감한 데이터 보호 프로세스를 간소화합니다. 업계 보안 표준과 사용자 친화적인 API를 결합하여 최적의 성능을
            유지하는 동시에 보안에 대한 걱정을 덜어줍니다.`
        ]
    },
    {
        eventKey: "10",
        label: "비즈니스 지속성",
        title: "사이트 전체 수준의 장애가 발생해도 시스템은 계속 실행됩니다.",
        text: [
            `사이트 전반에 걸쳐 장애가 발생하면 비즈니스 운영이 중단되고 막대한 비용이 소요될 수 있습니다. 적절한 장애 복구 전략을 사용하면 단점을 최소화하면서 
            운영을 계속할 수 있습니다.`,
            `Hazelcast 플랫폼은 기본 클러스터에서 대기 클러스터(또는 활성-활성을 포함한 여러 토폴로지)로 데이터를 증분적으로 복사하여 매우 작은 복구 지점 목표(RPO)로 
            동일한 클러스터를 보장하는 효율적인 WAN 복제 기능을 제공합니다. 자동 장애 복구 조치 기능을 사용하면 모든 클라이언트/애플리케이션이 
            백업 클러스터로 신속하게 리다이렉션되어 RTO(복구 시간 목표)가 최소화됩니다.`
        ]
    },
    {
        eventKey: "11",
        label: "블루-그린 배포 지원",
        title: "업그레이드 리스크와 유지 관리 가동 중지 시간을 줄여줍니다.",
        text: [
            `블루-그린 배포는 "블루"와 "그린"이라는 두 개의 개별 클러스터를 배포하여 유지 관리 가동 중지 시간과 관련 위험을 줄이는 IT 방식을 의미합니다. 
            정상 작동 중에 블루 클러스터는 프로덕션 상태이고 그린 클러스터는 준비용입니다.`,
            `그린 클러스터가 프로덕션 준비가 되면 클라이언트와 애플리케이션이 블루 클러스터에서 그린 클러스터로 리디렉션됩니다. 오류가 발생하면 클라이언트/애플리케이션이 
            원래 블루 클러스터로 되돌아갑니다. Hazelcast 플랫폼은 프로덕션 용도로 액세스할 클러스터를 애플리케이션에 신속하게 알리는 전환 기능을 제공합니다.`
        ]
    }
];

const PlatformFeaturesSection = () => {
    return (
        <BaseCard color="baseCard-ice">
            <Typography variant="title3" color="sky" className="platformFeatures-section-title">
                Hazelcast 플랫폼 주요 기능
            </Typography>
            <MyTab tabContents={tabContents} />
        </BaseCard>
    );
};

export default PlatformFeaturesSection;