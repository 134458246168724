import ResponsiveContext from "../context/ResponsiveContext";
import { useState, useEffect, useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LuPhone } from "react-icons/lu";
import './ContactUsButton.css';

const ContactUsButton = () => {
    const navigate = useNavigate();
    const { isMobile } = useContext(ResponsiveContext);
    const [isVisible, setIsVisible] = useState(false);

    // 사용자가 페이지를 어느 정도 아래로 스크롤했을 때 버튼을 보여주는 함수
    const toggleVisibility = () => {
        if (window.scrollY > 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const handleClick = () => {
        navigate('/#contact');
    };

    return (
        <>
            {isVisible &&
                <OverlayTrigger
                    placement="left"
                    delay={{ show: 100, hide: 300}}
                    disabled={isMobile}
                    overlay={
                        <Tooltip>
                            Contact Us
                        </Tooltip>
                    }
                >
                    <button className="contact-us-btn" onClick={handleClick}>
                        <LuPhone color='black' />
                    </button>
                </OverlayTrigger>
            }
        </>
    );
};

export default ContactUsButton;