import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import Button from "./atoms/Button";
import ImageSection from "./atoms/ImageSection";
import { Row, Col } from "react-bootstrap";
import InstantActionImg from '../assets/images/instant-action.png';
import './InstantActionSection.css';

const InstantActionSection = () => {
    return (
        <BaseCard color="baseCard-dusk">
            <Row>
                <Col md={7}>
                    <Typography variant="title3" color="sky" className="instantActionSection-tit">
                        스트리밍 데이터에 즉시 반응하는 애플리케이션 구축
                    </Typography>
                    <div className="d-md-none order-2 instantAction-img-container mb-5 md-mb-0">
                        <ImageSection
                            image={InstantActionImg}
                            style={{ maxHeight: '550px' }}
                            imageDescription="Instant-Action"
                        />
                    </div>
                    <div className="instantActionSection-text-container">
                    <Typography variant="body2" color="white" className="instantActionSection-text">
                        Hazelcast 플랫폼은 스트림 처리 엔진과 빠른 데이터 저장소의 강력한 조합입니다. 이는 실시간 스트리밍 데이터를 처리하고,
                        이를 과거의 정보와 함께 분석하여 기업의 즉각적인 대응을 도울 수 있도록 설계되었습니다.
                    </Typography>
                    <Typography variant="body2" color="white" className="instantActionSection-text">
                        간단히 말해, 우리 플랫폼의 독특한 통합 구조는 아키텍처 내의 별도의 소프트웨어 구성 요소의 갯수를 줄여 애플리케이션의 개발 및 배포 속도를 높입니다.
                    </Typography>
                    </div>
                    <Button variant="btnGradient-sky-lime" className="instantActionSetion-btn" href="https://hazelcast.com/products/hazelcast-platform/" newTab={true}>
                        플랫폼 특징 보러가기
                    </Button>
                </Col>
                <Col md={5} className="instantAction-img-container d-none d-md-block">
                    <ImageSection
                        image={InstantActionImg}
                        style={{ maxHeight: '550px' }}
                        imageDescription="Instant-Action"
                    />

                </Col>
            </Row>
        </BaseCard>
    );
};

export default InstantActionSection;