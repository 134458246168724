import React from 'react';
import { useNavigate } from 'react-router-dom';
import './button.css';

const Button = ({ children, variant, containerClassName, className, onClick, newTab, href }) => {
    const buttonClass = `button ${variant} ${className || ''}`;

    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        /* 새 창으로 띄우기 */
        if (newTab && href) {
            window.open(href, '_blank', 'noopener, noreferrer');
        }
        else if (href) {
            navigate(href);
        }
    }

    return (
        <div className={containerClassName}>
            <button className={buttonClass} onClick={handleClick}>
                {children}
            </button>
        </div>
    );
};

export default Button;