import Dropdown from 'react-bootstrap/Dropdown';
import Typography from './Typography';
import { IoIosArrowDown } from "react-icons/io";
import './Dropdown.css';


function MyDropdown({ dropdownContents, value, onChange }) {    
    const handleOptionClick = (option) => {
        onChange(option);
    }

    return (
        <Dropdown className="myDropdown">
            <Dropdown.Toggle id="dropdown-basic" className="myDropdown-btn">
                <Typography variant="body2" className="myDropdown-btn-text">
                    {value ? value : dropdownContents.title}
                </Typography>
                <span className="ms-auto">
                    <IoIosArrowDown />
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="myDropdown-menu">
                {dropdownContents.options.map((option, idx) => (
                    <Dropdown.Item key={idx} href="#/action-1" className="myDropdown-item" onClick={() => handleOptionClick(option)}>
                        {option}
                        </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default MyDropdown;