import { Row, Col } from 'react-bootstrap';
import UseCaseCard from '../components/molecules/UseCaseCard';

function RenderedUseCaseCards({ cardDetails }) {
    // 하위 배열로 분할
    const chunkArray = (array, chunkSize) => {
        let result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    // cardDetails 배열을 3개 단위로 분할
    const rows = chunkArray(cardDetails, 3);

    return (
        <>
            {rows.map((row, rowIndex) => (
                <Row key={rowIndex} className="g-4">
                    {row.map((cardDetail, colIndex) => (
                        <Col key={colIndex} xl={4} className="">
                            <UseCaseCard cardDetail={cardDetail} />
                        </Col>
                    ))}
                </Row>
            ))}
        </>
    );
}

export default RenderedUseCaseCards;
