import Accordion from 'react-bootstrap/Accordion';
import Typography from './Typography';
import { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import './Accordion.css';

function MyAccordion({ accordionContents }) {
    const [activeKey, setActiveKey] = useState(accordionContents[0].eventKey);

    const toggleAccordion = (key) => {
        if (activeKey === key) {
            setActiveKey(null);
        } else {
            setActiveKey(key);
        }
    };

    return (
        <Accordion defaultActiveKey={accordionContents[0].eventKey} className="myAccordion">
            {accordionContents.map((content, idx) => (
                <Accordion.Item key={idx} eventKey={content.eventKey} className="myAccordion-item">
                    <Accordion.Header className="myAccordion-tit-container" onClick={() => toggleAccordion(content.eventKey)}>
                        <Typography variant="title6" className="myAccordion-tit">
                            {content.title}
                        </Typography>
                        <span className="ms-auto">
                            {activeKey === content.eventKey ? <AiOutlineMinus /> : <AiOutlinePlus />}
                        </span>
                    </Accordion.Header>
                    <Accordion.Body className="myAccordion-text-container">
                        {content.text.map((par, idx) => (
                            <Typography variant="body2" className="myAccordion-text">
                                {par}
                            </Typography>
                        ))}
                        {content.list && content.list.map((listItem, idx) => (
                            <Typography variant="body2" className="myAccordion-list">
                                <FaCheck color='#10A4B3' style={{ marginRight: '1%' }} />
                                {listItem}
                            </Typography>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default MyAccordion;