import BaseCard from './atoms/BaseCard';
import { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Typography from './atoms/Typography';
import { renderedTabContent } from './molecules/ResourceCard';
import './ResourceSection.css';

/*
    {
        variant: "small/large",
        subTitle: "서브타이틀",
        title: "타이틀",
        text: "설명",
        link: "링크",
        buttonText: "버튼 문구",
        secondResource: {
            subTitle: "서브타이틀",
            title: "타이틀",
            text: "설명",
            link: "링크",
            buttonText: "버튼 문구"
    }
*/
const tabData = {
    business: [
        {
            variant: "large",
            title: "Hazelcast는 The Forrester Wave™: 스트리밍 데이터 플랫폼, 2023년 4분기 Strong Performer로 선정되었습니다.",
            buttonText: "리포트 보러가기",
            buttonLink: "https://hazelcast.com/resources/forrester-wave-streaming-data-platforms-2023/"
        },
        {
            variant: "small",
            subTitle: "case study",
            title: "PSA 앤트워프는 실시간 비즈니스 최적화를 통해 운영 비용을 33% 절감했습니다.",
            link: "https://hazelcast.com/resources/psa-antwerp-cuts-operational-costs-by-33-by-optimizing-their-business-in-real-time/"
        },
        {
            variant: "small",
            subTitle: "",
            title: "올바른 기술이 고객 경험을 주도합니다. ",
            link: "https://hazelcast.com/why-hazelcast/"
        },
        {
            variant: "large",
            title: "Hazelcast, Gartner® 마켓 가이드의 이벤트 스트림 처리 부문에 선정",
            text: "이벤트 스트림 처리를 위한 Gartner® 시장 가이드는 ESP 플랫폼이 필요한 이유와 현재 사용 가능한 제품에 대한 확실한 개요를 제공합니다.",
            link: "https://hazelcast.com/resources/gartner-market-guide-for-event-stream-processing/"
        }
    ],
    architect: [
        {
            variant: "large",
            title: "Hazelcast vs. Redis : 실용적인 비교",
            text: "Hazelcast가 스트림 처리 요구 사항에 대한 모든 기반을 어떻게 처리하는지 알아보세요. 그 중 많은 부분은 Redis에서 사용할 수 없습니다.",
            buttonText: "리포트 보러가기",
            buttonLink: "https://hazelcast.com/resources/hazelcast-vs-redis-practical-comparison/"
        },
        {
            variant: "small",
            subTitle: "웹세미나",
            title: "스트림 처리의 현주소",
            link: "아키텍트-링크-2"
        },
        {
            variant: "small",
            subTitle: "케이스 스터디",
            title: "PSA 앤트워프는 실시간 비즈니스 최적화를 통해 운영 비용을 33% 절감했습니다.",
            link: "https://hazelcast.com/resources/psa-antwerp-cuts-operational-costs-by-33-by-optimizing-their-business-in-real-time/"
        },
        {
            variant: "large",
            subTitle: "애널리스트 리포트",
            title: "Forrester는 The Forrester Wave™에서 Hazelcast를 최우수 성능 부문에 선정했습니다.",
            link: "https://hazelcast.com/resources/forrester-wave-streaming-data-platforms-2023/",
            secondResource: {
                subTitle: "백서",
                title: "아키텍트를 위한 Hazelcast 플랫폼 가이드",
                link: "https://hazelcast.com/resources/architects-view-hazelcast/"
            }
        }
    ],
    developer: [
        {
            variant: "large",
            title: "발견하고, 배우고, 공유하고 성장하세요.",
            text: "당신의 프로젝트, 경험, 그리고 통찰을 Hazelcast 개발자 커뮤니티와 공유하세요.",
            buttonText: "커뮤니티 참여하기",
            buttonLink: "https://hazelcast.com/developers/"
        },
        {
            variant: "small",
            subTitle: "블로그",
            title: "컨텍스트 데이터로 Kafka 애플리케이션을 풍부하게 만드세요.",
            link: "https://hazelcast.com/blog/enriching-kafka-applications-with-contextual-data/"
        },
        {
            variant: "small",
            subTitle: "",
            title: "Hazelcast 체험하기",
            link: "https://hazelcast.com/get-started/"
        },
        {
            variant: "large",
            subTitle: "2024 3/7",
            title: "Hazelcast 플랫폼 필수사항: 라이브 가상 워크숍",
            text: "",
            link: "https://hazelcast.com/resources/hazelcast-live-virtual-workshop/",
            secondResource: {
                subTitle: "2024 3/19-20",
                title: "런던 Kafka Summit에서 Hazelcast와 함께 레벨업하세요.",
                text: "",
                link: "추가-아키텍트-링크-5"
            }
        }
    ]
};


function ResourceSection() {
    const [activeKey, setActiveKey] = useState('business');

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    return (
        <BaseCard color="baseCard-dusk" baseClassName='resourceSection-base' bodyClassName='resourceSection-body' dataAos="fade-up">
            <Typography variant="title1" className="white textShadow resource-section-tit" >
                분야 별 인사이트
            </Typography>
            <Tab.Container id="custom-tabs" defaultActiveKey="architect">
                <Nav variant="tabs" className="custom-tab-menu">
                    <Nav.Item>
                        <Nav.Link eventKey="business">
                            <Typography variant="title6">
                                비즈니스
                            </Typography>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="architect">
                            <Typography variant="title6">
                                아키텍트
                            </Typography>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="developer">
                            <Typography variant="title6">
                                개발자
                            </Typography>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    {Object.keys(tabData).map((key) => (
                        <Tab.Pane eventKey={key} key={key} className='custom-tabPanel'>
                            {renderedTabContent(tabData, key)}
                        </Tab.Pane>
                    ))}
                </Tab.Content>
            </Tab.Container>
        </BaseCard>
    );
}

export default ResourceSection;