import { createContext, useContext, useEffect, useState } from "react";

const ResponsiveContext = createContext();

function ResponsiveProvider ({ children }) {
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 767px)").matches);

    useEffect(() => {
        const handler = () => setIsMobile(window.matchMedia("(max-width: 767px)").matches);

        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }, []);


    return (
        <ResponsiveContext.Provider value={{isMobile}}>
            {children}
        </ResponsiveContext.Provider>
    );
};

export {ResponsiveProvider};
export default ResponsiveContext;