import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import './PieChart.css';

const PieChartExample = ({
    innerPieData,
    outerPieData,
    width,
    height,
    innerPieRadius,
    outerPieRadius_start,
    outerPieRadius_end,
    outerPieColor,
    innerPieColor,
    outerPieLabel,
    innerPieLabel
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const chartRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            // entries는 관찰 대상이 여러 개일 수 있으므로, 배열로 전달됩니다.
            entries.forEach(entry => {
                // isIntersecting은 타겟 요소가 뷰포트에 들어왔는지 여부를 나타냅니다.
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    // 한 번 애니메이션을 실행한 후, observer를 해제합니다.
                    observer.unobserve(entry.target);
                }
            });
        });        

        // chartRef.current가 실제 DOM 요소를 참조하고 있을 때만 observer를 등록합니다.
        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        return () => {
            if (chartRef.current) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <div ref={chartRef} className='pieChart-div'>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={width} height={height}>
                    <Pie
                        data={outerPieData}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        innerRadius={outerPieRadius_start}
                        outerRadius={outerPieRadius_end}
                        fill={outerPieColor}
                        label={outerPieLabel}
                        stroke='none'
                    />
                    {isVisible && (
                        <Pie
                            data={innerPieData}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            outerRadius={innerPieRadius}
                            fill={innerPieColor}
                            label={innerPieLabel}
                            startAngle={90}
                            endAngle={450}
                            stroke="rgba(209, 212, 217, 1)"
                            strokeWidth={2}>
                            {
                                innerPieData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))
                            }
                        </Pie>
                    )}
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PieChartExample;