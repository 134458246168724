import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import FlipCard from "./molecules/FlipCard";
import { Row, Col } from "react-bootstrap";
import heart from '../assets/images/widget-icons/heart.png';
import partner from '../assets/images/widget-icons/partner.png';
import magnifyingGlass from '../assets/images/widget-icons/magnifying-glass.png';
import screen from '../assets/images/widget-icons/screen.png';
import fastTime from '../assets/images/widget-icons/fast-time.png';
import './OurValueSection.css';

// const cardDetails = [
//     {
//         imgUrl: heart,
//         title: "고객을 향한 열정",
//         text: {
//             1: "'평생 고객' 철학",
//             2: "고객과 공감합니다.",
//             3: "비즈니스 결과에 집중합니다."
//         }
//     },
//     {
//         imgUrl: partner,
//         title: "혁신 주도",
//         text: {
//             1: "모방이 아닌, 혁신",
//             2: "독보적이며 최첨단입니다.",
//             3: "기술적이고 심플한 제품"
//         }
//     },
//     {
//         imgUrl: magnifyingGlass,
//         title: "끝없는 탐구심",
//         text: {
//             1: "함께 협력하고 배웁니다.",
//             2: "좋은 아이디어가 최우선입니다.",
//             3: "자아보다 공익",
//             4: "기회를 극대화하고 포용합니다."
//         }
//     },
//     {
//         imgUrl: screen,
//         title: "미래를 봅니다.",
//         text: {
//             1: "우리의 사람, 제품, 회사의 거울",
//             2: "방관하지 않고 성취해냅니다.",
//             3: "추진력과 야심"
//         }
//     },
//     {
//         imgUrl: fastTime,
//         title: "누구보다 빠르게",
//         text: {
//             1: "액션을 취합니다.",
//             2: "민첩한 적응력",
//             3: "강력한 문제 해결 능력"
//         }
//     }
// ];

const cardDetails = [
    {
        imgUrl: heart,
        title: "고객을 향한 열정",
        text: {
            1: "Embrace “Customers for Life” philosophy",
            2: "Empathize with customers",
            3: "Focus on their business results"
        }
    },
    {
        imgUrl: partner,
        title: "혁신 주도",
        text: {
            1: "We innovate, not imitate",
            2: "Unique, cutting edge",
            3: "Technical product, simplified"
        }
    },
    {
        imgUrl: magnifyingGlass,
        title: "끝없는 탐구심",
        text: {
            1: "Collaborate and learn together",
            2: "Best ideas take precedence",
            3: "Value the common good over ego",
            4: "Excel and embrace opportunities"
        }
    },
    {
        imgUrl: screen,
        title: "미래를 봅니다",
        text: {
            1: "A mirror of our people, product, and company",
            2: "We’re achievers, not bystanders",
            3: "Driven and aspiring"
        }
    },
    {
        imgUrl: fastTime,
        title: "누구보다 빠르게",
        text: {
            1: "Bias for action",
            2: "Agile adaptation",
            3: "Empowered problem-solving"
        }
    }
];

const OurValuesSection = () => {
    return (
        <BaseCard color="baseCard-ice">
            <Typography variant="title3" className="ourValueSection-tit">
                우리의&nbsp;
                <Typography color="gradient-sky-deepSea">
                    가치
                </Typography>
            </Typography>
            <Row className="g-3 g-sm-5 mb-3 mb-sm-5">
                <Col lg={4} className="ourValueSection-text-container">
                    <Typography variant="body2" className="ourValueSection-text">
                        Hazelcast에서는 복잡성을 단순화하고 사용자의 요구에 맞는 독자적인 최첨단 고급 솔루션을 제공하기 위해 지속적인 혁신, 속도, 민첩성 및 행동에 대한 신념에 전념하고 있습니다.
                    </Typography>
                </Col>
                {cardDetails.slice(0, 2).map((cardDetail, index) => (
                    <Col key={index} lg={4}>
                        <FlipCard cardDetail={cardDetail} />
                    </Col>
                ))}
            </Row>
            <Row className="g-3 g-sm-5 mb-3 mb-sm-5">
                {cardDetails.slice(2, 6).map((cardDetail, index) => (
                    <Col key={index} lg={4}>
                        <FlipCard cardDetail={cardDetail} />
                    </Col>
                ))}
            </Row>
        </BaseCard>
    );
};

export default OurValuesSection;