import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import ImageCard from "./molecules/ImageCard";
import { Row, Col } from 'react-bootstrap';
import RealTimeOffersImage from '../assets/images/real-time-offers.jpg';
import PaymentsCaseStudyImage from '../assets/images/payments-case-study.jpg';
import './UseCaseSection.css';

const cardDetails = [
    {
        imgUrl: RealTimeOffersImage,
        title: "실시간 제안",
        subTitle: "전환율 400% 상승",
        text: "BNP Paribas 은행은 실시간 맞춤형 제안을 통해 놀라운 전환율 증가를 성취했습니다.",
        btn: true,
        href: "https://hazelcast.com/resources/adding-real-time-stream-processing-to-promote-offers-at-the-right-time/"
    },
    {
        imgUrl: PaymentsCaseStudyImage,
        title: "결제 처리",
        subTitle: "초당 10만 건 이상의 결제",
        // text: "한 다국적 은행은 디지털 변환을 통해 40개국에서의 결제 처리를 성공적으로 개선했습니다. 그 결과 성능, 확장성 및 인지도에서 압도적인 우위를 선점했습니다.",
        text: "한 글로벌 은행은 디지털 변환을 통해 40개국에서의 결제 처리를 성공적으로 개선했습니다. 그 결과 성능, 확장성 및 인지도에서 압도적인 우위를 선점했습니다.",
        btn: true,
        href: "https://hazelcast.com/resources/modernizing-a-financial-transaction-infrastructure-for-cross-border-expansion/"
    }
];


function UseCaseSection() {
    return (
        <BaseCard color="baseCard-ice" dataAos="fade-up">
            <Typography variant="title2" color="sky">
                Hazelcast와 함께 비즈니스 혁신을 이끌어보세요.
            </Typography>
            <Row className="justify-content-center g-xl-5 g-3 useCase-row">
                {cardDetails.map((cardDetail, index) => (
                    <Col xl={6} md={6} key={index} className="useCase-col">
                        <div className="useCase-div" data-aos="flip-up">
                            <ImageCard cardDetail={cardDetail} />
                        </div>
                    </Col>
                ))}
            </Row>
        </BaseCard>
    );
}

export default UseCaseSection;