import KBbank from '../assets/images/client-logo/local/KB_s_kr3.jpg';
import GEPS from '../assets/images/client-logo/local/GovernmentEmploymentsPensionCorporation.jpg';
import MOIS from '../assets/images/client-logo/local/Ministry_of_the_Interior_and_Safety_of_the_Republic_of_Korea_Logo_(horizontal).svg.png';
import PPS from '../assets/images/client-logo/local/PublicProcurementService.jpg';
import DUDC from '../assets/images/client-logo/local/DaeguUrbanDevelopmentCorporation.jpg';
import KLID from '../assets/images/client-logo/local/KLID.jpg';
import NMK from '../assets/images/client-logo/local/NationalMuseumofKorea.jpg';
import KDCA from '../assets/images/client-logo/local/Korea_Disease_Control_and_Prevention_Agency_Logo_(horizontal).svg.png';
import KOSAF from '../assets/images/client-logo/local/KoreaStudentAidFoundation.jpg';
import SR from '../assets/images/client-logo/local/sr.png';
import KDBbank from '../assets/images/client-logo/local/kdb-img.jpg';
import SSIS from '../assets/images/client-logo/local/ssis.png';
import STK from '../assets/images/client-logo/local/stk_en.png';
import HanwhaLife from '../assets/images/client-logo/local/hanwhalife.png';
import NIRS from '../assets/images/client-logo/local/NIRS.png';

const localCardDetails = {
    0: {
        imgUrl: KLID, // 한국지역정보개발원
        title: "KLID"
    },    
    // 1: {
    //     imgUrl: NMK, // 국립중앙박물관
    //     title: "NMK"
    // },
    2: {
        imgUrl: MOIS, // 행정안전부
        title: "MOIS"
    },
    3: {
        imgUrl: GEPS, // 공무원 연금 공단
        title: "GEPS"
    },
    4: {
        imgUrl: PPS, // 조달청
        title: "PPS"
    },
    // 5: {
    //     imgUrl: DUDC, // 대구 도시 공사
    //     title: "DUDC"
    // },
    6: {
        imgUrl: KBbank, // KB 국민은행
        title: "KBbank"
    },
    // 7: {
    //     imgUrl: KDCA, // 질병관리청
    //     title: "KDCA"
    // },
    // 8: {
    //     imgUrl: KOSAF, // 한국장학재단
    //     title: "KOSAF"
    // },
    // 9: {
    //     imgUrl: SR, // SR
    //     title: "SR"
    // },
    // 10: {
    //     imgUrl: KDBbank, // KDB 산업은행
    //     title: "KDBbank"
    // },
    // 11: {
    //     imgUrl: SSIS, // 한국사회보장정보원
    //     title: "SSIS"
    // },
    12: {
        imgUrl: STK, // 스포츠토토
        title: "STK"
    },
    13: {
        imgUrl: HanwhaLife, // 한화생명
        title: "HanwhaLife"
    },
    14: {
        imgUrl: NIRS, // 국가정보자원관리원
        title: "NIRS"
    },
}

export default localCardDetails;