import { useState } from "react";
import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col, CardGroupProps, Card } from 'react-bootstrap';
import { Map, MapInfoWindow, MapMarker, useKakaoLoader } from "react-kakao-maps-sdk";
import { FaRegMap } from "react-icons/fa";
import { LuMail, LuPhone } from "react-icons/lu";
import './ContactSection.css';

function ContactSection() {

    return (
        // <BaseCard color="baseCard-dusk" baseClassName="contact-section" dataAos="fade-up">
        <BaseCard color="baseCard-dusk" baseClassName="contact-section" id="contact" dataAos="fade-up">
            <Typography variant="title1" color="sky" className="alignCenter">
                Contact
            </Typography>
            <Row>
                <Col lg={6} className="me-lg-2 mb-lg-0 mb-3">
                    <Map
                        id="map"
                        center={{
                            // 지도의 중심좌표
                            lat: 37.413640,
                            lng: 126.976918,
                        }}
                        style={{
                            // 지도의 크기
                            // width: "100%",
                            // height: "400px",
                        }}
                        level={4} // 지도의 확대 레벨
                    >
                        <MapMarker
                            position={{ lat: 37.413750, lng: 126.977118 }}
                            clickable={true}
                            onClick={() => { window.open('https://kko.to/LTJ7di-91y', '_blank') }}
                        >
                        </MapMarker>
                    </Map>
                </Col>
                <Col lg={5}>
                    <div className="contact-text">
                        <Typography variant="title5" color="black">
                            <FaRegMap />주소
                        </Typography>
                        <Typography variant="body2" color="black">
                            경기도 과천시 과천대로 7길 33 디테크타워 A동 1309호 헤이즐캐스트코리아 <br />
                            A1309, D-Tech Tower, 33, Gwacheon-daero 7-gil, Gwacheon-si, Gyeonggi 13840, Korea
                        </Typography>
                    </div>
                    <div className="contact-text">
                        <Typography variant="title5" color="black">
                            <LuMail />Email
                        </Typography>
                        <Typography variant="body2" color="black">
                            info@hazelcast.co.kr <br />
                            문의 : ps.kang@hazelcast.co.kr (강필수 이사)
                        </Typography>
                    </div>
                    <div className="contact-text">
                        <Typography variant="title5" color="black">
                            <LuPhone />Phone
                        </Typography>
                        <Typography variant="body2" color="black">
                            +82 2 6390 6052 <br />
                            010 9282 6815
                        </Typography>
                    </div>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default ContactSection;