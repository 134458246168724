import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { ResponsiveProvider } from './context/ResponsiveContext';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
    <ResponsiveProvider>
        <App />
    </ResponsiveProvider>
);