import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col } from "react-bootstrap";
import './MilestonesSection.css';

const MilestonesSection = () => {
    return (
        <BaseCard color="baseCard-dusk" baseClassName="milestones-section">
            <Typography variant="title2" color="white" className="textShadow milestones-section-tit">
                실시간 데이터는 우리의 DNA입니다.
            </Typography>
            <Row>
                <Col md={10}>
                    <Typography variant="body1" color="white" className="milestones-section-text">
                        2012년 설립된 Hazelcast는 빠른 데이터 접근을 재구상하는 것에 초점을 맞추었습니다. 우리는 세계에서 가장 진보된 인메모리 데이터 그리드(IMDG)를 만드는 것으로
                        시작했습니다. 이 과정에서 데이터를 데이터베이스에 저장하기 전에 실시간으로 처리하는 것의 혁신적인 잠재력을 최초로 인식했습니다. 그리고 이를 시작으로 우리는
                        최초이자 가장 실용적인 통합 실시간 데이터 플랫폼을 개발하기 시작했습니다.
                    </Typography>
                    <div className="milestones">
                        <Typography variant="title4" color="white" className="milestones-tit">
                            연혁
                        </Typography>
                        <Row className="g-3 g-md-5">
                            <Col className="milestone" xs={12}>
                                <Typography variant="title5" color="white" className="milestones-subTit">
                                    2012
                                </Typography>
                                <Typography variant="body2" color="white" className="milestones-text">
                                    Hazelcast는 시간을 경쟁 우위로 활용하려는 기업을 위한 초고속 데이터 저장소를 소개합니다.
                                </Typography>
                            </Col>
                            <Col className="milestone" xs={12}>
                                <Typography variant="title5" color="white" className="milestones-subTit">
                                    2017
                                </Typography>
                                <Typography variant="body2" color="white" className="milestones-text">
                                    Hazelcast는 즉각적인 조치와 의사 결정을 위한 실시간 스트림 처리 엔진을 공개하고 이를 고속 데이터 저장소와 병합하여 통합 실시간 데이터 플랫폼의 기반을 구축합니다.
                                </Typography>
                            </Col>
                            <Col className="milestone" xs={12}>
                                <Typography variant="title5" color="white" className="milestones-subTit">
                                    2022
                                </Typography>
                                <Typography variant="body2" color="white" className="milestones-text">
                                    Hazelcast는 기계 학습(ML) 모델을 운영하여 명확하지 않은 추세, 패턴, 신호 및 이상 현상에 즉각적으로 대응할 수 있는 기능을 갖춘 통합 실시간 데이터 플랫폼을 출시합니다.
                                </Typography>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default MilestonesSection;