import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import Button from "./atoms/Button";
import { openInNewTab } from "../utils/OpenInNewTab";
import { Row, Col } from 'react-bootstrap/';
import './OfficialPartnerSection.css';
import ExorLogo from '../assets/images/KakaoTalk_20240401_113132924_04.png';

function OfficialPartnerSection() {
    return (
        <BaseCard baseClassName="official-partner-section" dataAos="fade-up">
            <Typography variant="title1" className="alignCenter textShadow">
                공식 파트너
            </Typography>
            <Row className="mb-4">
                <Col lg={6} className="mb-lg-0 mb-4">
                    <div className="partner-logo">
                        <img src={ExorLogo} alt="ExorLogo" />
                    </div>
                </Col>
                <Col lg={6}>
                    <Typography variant="body3" className="official-partner-text">
                        엑솔은 IMCP(In-Memory Computing Platform)솔루션의 국내 총판사로 다양한 산업분야에서 탁월한 SI 서비스를 제공하고 있습니다.
                    </Typography>
                    <Typography variant="body3" className="official-partner-text">
                        이를 통해 국내 금융기관, 정부 부처, 공공기관 등에 맞춤형 시스템 개발, 소프트웨어와 하드웨어 장비의 판매 및 설치를 통해 고객의 비즈니스를 종합적으로 지원하고 있습니다.
                    </Typography>
                    <Typography variant="body3" className="official-partner-text">
                        헤이즐캐스트 IMCP를 활용하여 첨단 시스템 아키텍처 솔루션을 제공함으로써 고객들에게 성능 향상, TCO 절감, 안정적인 운영 환경, 대규모 데이터의 실시간 처리를 가능하게
                        하고 다양한 고객들의 다양한 요구사항을 충족시키고 있습니다.
                    </Typography>
                    {/* <Typography variant="body3" className="official-partner-text">
                        엑솔은 고객과의 끊임없는 소통을 통해 비즈니스의 변화에 신속하게 대응하며 항상 고객의 만족을 위해 최선을 다하고 있습니다. 또한, 목표를 달성하기 위해 고객과
                        협력하여 보안 패치, 기술 지원 등을 제공하고 있습니다.
                    </Typography>
                    <Typography variant="body3" className="official-partner-text">
                        이러한 종합적인 ICT 서비스는 국내 다양한 산업분야에서 고객들의 비즈니스를 지원하고 있으며, 고객과의 소통을 통해 지속적인 성장과 발전을 이루고 있습니다.
                    </Typography> */}
                </Col>
            </Row>
            <Button onClick={() => openInNewTab('http://exor.co.kr/')} variant="btnBlueberry" containerClassName="official-partner-btn-container" className="official-partner-btn">Exor 홈페이지</Button>
        </BaseCard>
    );
};

export default OfficialPartnerSection;
