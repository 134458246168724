import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import { Row, Col } from "react-bootstrap";
import './OurStorySection.css';

const OurStorySection = () => {
    return (
        <BaseCard color="baseCard-dusk" baseClassName="ourStory-section">
            <Typography variant="title3" color="white" className="textShadow ourStory-section-tit">
            전 세계 거래의 2/3가 Hazelcast 기반 애플리케이션을 통해 전달됩니다.
            </Typography>
            <Row>
                <Col md={10}>
                    <Typography variant="title4" color="gradient-sky-lime" className="ourStory-section-subTit">
                        Hazelcast의 이야기
                    </Typography>
                    <Typography variant="body1-5" color="white" className="ourStory-section-text">
                        Hazelcast의 여정은 '비즈니스 애플리케이션의 느린 반응 시간을 해결하자'는 비전 하나에서 시작되었습니다. 그래서 우리는 초고속 데이터 저장소를 구축하고,
                        실시간 스트림 처리와 머신 러닝 추론을 받아들였고, 그 결과 우리의 통합 플랫폼이 탄생하게 되었습니다. <br /><br />
                        <b style={{color: "#C91268"}}>우리의 목표</b>는 빠르고 똑똑한 데이터 솔루션을 통해 기업들이 즉각적으로 대응하고, 고객 경험을 향상시키며, 효율성을 높이고 위험은 최소화하며
                        새로운 수익원을 창출할 수 있도록 하는 것입니다.
                    </Typography>
                    <Typography variant="title4" color="sky" className="ourStory-section-subTit">
                    통합 실시간 데이터 플랫폼으로의 진화
                    </Typography>
                    <Typography variant="body1-5" color="white" className="ourStory-section-text">
                    Hazelcast는 빠른 데이터 액세스를 재구성하는 데 중점을 두고 2012년에 설립되었습니다. 우리는 세계에서 가장 발전된 인메모리 데이터 그리드(IMDG)를 만드는 것부터 시작했습니다.
                    이 과정에서 우리는 데이터를 데이터베이스에 저장하기 전에 실시간으로 데이터를 처리하는 것의 혁신적인 잠재력을 먼저 식별했습니다. 그리하여 최초이자 가장 기능적인
                    통합 실시간 데이터 플랫폼을 개발하려는 노력이 시작되었습니다.
                    </Typography>
                    <div className="ourStory">
                        <Typography variant="title4" color="white" className="ourStory-tit">
                            연혁
                        </Typography>
                        <Row className="g-3 g-md-5">
                            <Col className="ourStory-col" xs={12}>
                                <Typography variant="title5" color="white" className="ourStory-subTit">
                                    2012
                                </Typography>
                                <Typography variant="body2" color="white" className="ourStory-text">
                                    Hazelcast는 시간을 경쟁 우위로 활용하려는 기업을 위한 초고속 데이터 저장소를 소개합니다.
                                </Typography>
                            </Col>
                            <Col className="ourStory-col" xs={12}>
                                <Typography variant="title5" color="white" className="ourStory-subTit">
                                    2017
                                </Typography>
                                <Typography variant="body2" color="white" className="ourStory-text">
                                    Hazelcast는 즉각적인 조치와 의사 결정을 위한 실시간 스트림 처리 엔진을 공개하고 이를 고속 데이터 저장소와 병합하여 통합 실시간 데이터 플랫폼의 기반을 구축합니다.
                                </Typography>
                            </Col>
                            <Col className="ourStory-col" xs={12}>
                                <Typography variant="title5" color="white" className="ourStory-subTit">
                                    2022
                                </Typography>
                                <Typography variant="body2" color="white" className="ourStory-text">
                                    Hazelcast는 기계 학습(ML) 모델을 운영하여 명확하지 않은 추세, 패턴, 신호 및 이상 현상에 즉각적으로 대응할 수 있는 기능을 갖춘 통합 실시간 데이터 플랫폼을 출시합니다.
                                </Typography>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default OurStorySection;