import React, { useState, useEffect } from 'react';
import { FaChevronUp } from "react-icons/fa";
import './ScrollTopButton.css';

const ScrollTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // 사용자가 페이지를 어느 정도 아래로 스크롤했을 때 버튼을 보여주는 함수
    const toggleVisibility = () => {
        if (window.scrollY > 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // 페이지 상단으로 스크롤하는 함수
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <button className="scroll-top-btn" onClick={scrollToTop}>
                    <FaChevronUp color='black' />
                </button>
            )}
        </>
    );
};

export default ScrollTopButton;
