import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga4';

function RouteChangeTracker() {
    const location = useLocation();

    // GA4 초기화 - react-ga4 사용 시
    // useEffect(() => {
    //     ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    // }, []);

    // 라우트 변경 시 페이지 뷰 추적
    useEffect(() => {
        // ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        window.gtag('event', 'page_view', { page_path: location.pathname + location.search });
    }, [location]);

    return null;
}

export default RouteChangeTracker;
