import { Helmet } from 'react-helmet-async';
import SlideSection from '../components/SlideSection';
import HazelcastPlatformSection from '../components/HazelcastPlatformSection';
import ClientsSection from '../components/ClientsSection';
import UseCaseSection from '../components/UseCaseSection';
import ResourceSection from '../components/ResourceSection';
import AvailablePlatformSection from '../components/AvailablePlatformSection';
import OfficialPartnerSection from '../components/OfficialPartnerSection';
import ContactSection from '../components/ContactSection';

const MainPage = () => {
    return (
        <>
        <Helmet>
            <title>Hazelcast Korea</title>            
            <meta property='og:description' content='실시간 데이터 처리와 대규모 분산 컴퓨팅을 위한 빠르고 확장 가능한 인메모리 컴퓨팅 플랫폼입니다.' />
            <meta property='og:url' content='https://hazelcastkorea.com' />
        </Helmet>
            <SlideSection />
            <HazelcastPlatformSection />
            <OfficialPartnerSection />
            <ClientsSection />
            <UseCaseSection />
            <ResourceSection />
            <AvailablePlatformSection />
            <ContactSection />
        </>
    )
};

export default MainPage;