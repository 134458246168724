import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToHash() {
    const location = useLocation();

    useEffect(() => {
        // 페이지 로드 시 해시가 존재하면 해당 섹션으로 스크롤
        const hash = location.hash;
        if (hash) {
            const id = hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop, 
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);
}

export default useScrollToHash;
