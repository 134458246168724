import { Form, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import { IoIosSearch, IoIosClose } from "react-icons/io";
import './SearchBar.css';

function SearchBar({ onSearch }) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch(searchTerm);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    return (
        <Form inline onSubmit={handleSubmit} className="searchBar-form">
            <Form.Control
                type="search"
                placeholder="키워드 검색"
                className="searchBar-form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
                <span className="searchBar-icon searchBar-close" >
                    <IoIosClose onClick={handleClearSearch} />
                </span>
            )}
            <span className="searchBar-icon" >
                <IoIosSearch onClick={handleSubmit} />
            </span>
        </Form>
    );
};

export default SearchBar;