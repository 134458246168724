import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ContactUsButton from "../components/ContactUsButton";
import ScrollTopButton from "./ScrollTopButton";
import './layout.css';
import useScrollToHash from "../utils/ScrollToHash";

const Layout = ({ children }) => {
    // 홈 화면에서는 배경에 dark-overlay 적용하지 않음
    const location = useLocation();
    const isHome = location.pathname === '/';
    const isError = location.pathname === '/error'; 

    useScrollToHash();

    return (
        <div className="layout-container">
            <Header />
            <main>
            <div className="background-image">
                {isError && <div className="white-overlay"></div>}
                {!isHome && <div className="dark-overlay"></div>}
            </div>
            {children}
            </main>
            <Footer />
            <div className="scrollBtn-container">
            <ContactUsButton />
            <ScrollTopButton />
            </div>
        </div>
    );
};

export default Layout;