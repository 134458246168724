import Typography from '../atoms/Typography';
import { Col } from 'react-bootstrap';
import './PageTitle.css';

const PageTitle = ({ children, colClassName }) => {
    return (
        <Col lg={10} className={`page-title ${colClassName}`}>
            <Typography variant="title1" className="white textShadow">
                {children}
            </Typography>
        </Col>
    );
};

export default PageTitle;
