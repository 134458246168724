import React from "react";

const ImageSection = ({ image, style, imageDescription }) => (
    <img
        src={image}
        style={style}
        alt={imageDescription}
        className="img-fluid"
    />
);

export default ImageSection;