import Button from '../atoms/Button';
import Card from 'react-bootstrap/Card';
import Typography from '../atoms/Typography';
import './IconCard.css';

function IconCard({ cardDetail, className, dataAos }) {
    return (
        <Card
            className={`icon-card ${className}`}
            data-aos={dataAos}>
            <Card.Body className='imgCard-body'>
                <Card.Img variant="top" src={cardDetail.imgUrl} className='icon-container' />
                <Typography variant="title6">
                    {cardDetail.title}
                </Typography>
                {cardDetail.text && 
                <Typography variant="body3" style={{ marginBottom: '10px' }}>
                    {cardDetail.text}
                </Typography>}                
            </Card.Body>
        </Card>
    );
}

export default IconCard;