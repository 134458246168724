import { useContext, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Typography from './Typography';
import ResponsiveContext from '../../context/ResponsiveContext';
import { FaCheck } from "react-icons/fa6";
import './Tab.css';

function MyTab ({ tabContents }) {
    // 모바일(768px 미만)에서 네비게이션 아이템 선택 시 컨텐츠로 스크롤
    const { isMobile } = useContext(ResponsiveContext);

    const contentRef = useRef(null);

    const handleSelect = (eventKey) => {
        console.log(isMobile);

        if (isMobile && contentRef.current) {
            contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey={tabContents[0].eventKey} onSelect={handleSelect}>
            <Row className="myTab-row">
                <Col md={3} className="myTab-col-nav">
                    <Nav variant="pills" className="flex-column myTab-nav">
                        {tabContents.map((content, idx) => (
                            <Nav.Item key={idx} className="myTab-nav-item">
                                <Nav.Link eventKey={content.eventKey}>
                                    <Typography variant="title6">
                                        {content.label}
                                    </Typography>
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
                <Col md={9} className="myTab-col-content" ref={contentRef}>
                    <Tab.Content>
                        {tabContents.map((content, idx) => (
                            <Tab.Pane key={idx} eventKey={content.eventKey}>
                                <Typography variant="title4" color="dusk" className="myTab-content-tit">
                                    {content.title}
                                </Typography>
                                {content.text.map((par, idx) => (
                                    <Typography variant="body2" className="myTab-content-par">
                                        {par}
                                    </Typography>
                                ))}
                                {content.list && content.list.map((listItem, idx) => (
                                    <div key={idx} className="tab-list-section">
                                        <Typography variant="body1-5" className="myTab-content-list-tit">
                                            <FaCheck color='#10A4B3' style={{ marginRight: '1%' }} />
                                            {listItem.listTitle}
                                        </Typography>
                                        <Typography variant="body3" className="myTab-content-list-text">
                                            {listItem.listText}
                                        </Typography>
                                    </div>
                                ))}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default MyTab;