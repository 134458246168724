import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import ImageSection from "./atoms/ImageSection";
import { Row, Col } from "react-bootstrap";
import Automate from '../assets/images/automate.png';
import './AutomateArchitectureSection.css';

const AutomateArchitectureSection = () => {
    return (
        <BaseCard color="baseCard-dusk">
            <Row>
                <Col lg={{ span: 3, offset: 1 }} sm={4} className="mb-4 mb-sm-0 automate-section-col-img">
                    <ImageSection
                        image={Automate}
                        style={{ maxHeight: '300px' }}
                        imageDescription="Automate"
                    />
                </Col>
                <Col lg={{ span: 7, offset: 1 }} sm={8} className="automate-section-col">
                    <div className="automate-section-text-container">
                        <div className="automate-section-text-body">
                            <Typography variant="title4" color="lime">
                                즉각적인 대응을 위한 데이터 아키텍처 자동화
                            </Typography>
                            <Typography variant="body1-5" color="white">
                                AI, 이벤트 기반 및 엣지 컴퓨팅 사용 사례에서 빠르고 효율적인 예측과 최적의 성능을 위해 실시간 데이터로 머신 러닝 모델을 원활하게 배포하세요.
                            </Typography>
                        </div>
                    </div>
                </Col>
            </Row>
        </BaseCard>
    );
};

export default AutomateArchitectureSection;