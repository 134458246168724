import BaseCard from "./atoms/BaseCard";
import Typography from "./atoms/Typography";
import AWSLogo from '../assets/images/available-platforms/2560px-Amazon_Web_Services_Logo.svg.png';
import AzureLogo from '../assets/images/available-platforms/Microsoft_Azure_Logo.svg.png';
import GoogleCloudLogo from '../assets/images/available-platforms/Google_Cloud_logo.svg.png';
import './AvailablePlatformSection.css';

const LogoContainer = ({ imgUrl, imgAlt }) => {
    return (
        <div className="platform-logo">
            <img src={imgUrl} alt={imgAlt} />
        </div>
    );
};

function AvailablePlatformSection() {
    return (
        <BaseCard color="baseCard-ice" dataAos="fade-up">
            <Typography variant="title2" className="alignCenter sky">
                사용 가능한 플랫폼
            </Typography>
            <div className="available-platform-container">
                <LogoContainer 
                    imgUrl={AzureLogo}
                    imgAlt="AzureLogo"
                />
                <LogoContainer 
                    imgUrl={GoogleCloudLogo}
                    imgAlt="GoogleCloudLogo"
                />
                <LogoContainer 
                    imgUrl={AWSLogo}
                    imgAlt="AWSLogo"
                />
            </div>
        </BaseCard>
    )
};

export default AvailablePlatformSection;