import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import './cards.css';

function BaseCard({ children, color, style, dataAos, baseClassName = '', bodyClassName='', id }) {
    return (
        <Card className={`baseCard ${baseClassName} ${color}`} id={id} style={style} data-aos={dataAos}>
            <Card.Body className={`${bodyClassName}`}>
                {children}
            </Card.Body>
        </Card>
    );
}

export default BaseCard;
